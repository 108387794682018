/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import { Drawer, Form, Button, Col, Row, Tooltip, Select, Input } from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import { Container } from './styles'

import { translate } from '~/utils/locale'

import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useContributorsTaskFilter } from '~/hooks/Tasks/useListContributorsTask'

const MaintenanceFilterModal: React.FC = () => {
  const {
    handleUpdateFilters,
    handleResetFilters,
    filterOptions,
    maintenance,
  } = useMaintenance()

  const { clients } = useListClients()
  const { contributorsTaskFilter } = useContributorsTaskFilter()

  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      customer: filterOptions?.customer,
      name: filterOptions?.name,
      responsible: filterOptions?.responsible,
    })
  }, [filterOptions, form])

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = (dataForm: any) => {
    handleUpdateFilters(dataForm)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        loading={!maintenance}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('TaskTypesFilter.cleanFilterTooltip')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_maintenance"
      >
        {isFilter
          ? translate('MaintenanceTable.filtersApply')
          : translate('MaintenanceTable.filters')}
      </Button>
      <Drawer
        forceRender
        title={translate('MaintenanceTable.maintenancePlanFilters')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row>
            <Col span={24}>
              <Form.Item label="Nome do Plano" name="name">
                <Input placeholder="Informe o nome do plano" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={translate('MaintenanceTable.client')}
                name="customer"
              >
                <Select
                  placeholder={translate('MaintenanceTable.selectClient')}
                >
                  {clients?.map((m) => (
                    <Select.Option key={m.id} value={m.contact.name}>
                      {m.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={translate('MaintenanceTable.responsibles')}
                name="responsible"
              >
                <Select
                  placeholder={translate('MaintenanceTable.selectResponsibles')}
                >
                  {contributorsTaskFilter?.map((m) => (
                    <Select.Option key={m.id} value={m.contact.name}>
                      {m.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('MaintenanceTable.apply')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('MaintenanceTable.clear')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default MaintenanceFilterModal
