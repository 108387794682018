export default {
  pt: {
    NewClients: {
      randomEmailError: 'Preencha o campo nome para gerar um email aleatório',
      successMessage: 'Cliente cadastrado com sucesso!',
      errorMessage: 'Erro ao cadastrar cliente.',
      cnpjInUseMessage:
        'Este CNPJ já se encontra em uso, isso não o impede de criar o cliente.',
      helmetTitle: 'Novo Cliente',
      pageHeaderTitle: 'Novo Cliente',
      cardTitle: 'Preencha os dados do cliente',
      keepRegisterText: 'Continuar cadastrando clientes?',
      buttonText: 'Cadastrar',
      nameLabel: 'Nome',
      nameRuleMessage: 'Por favor informe o nome do cliente',
      namePlaceholder: 'Nome do cliente',
      emailLabel: 'E-mail',
      emailGenerateTooltip: 'Gerar email aleatório falso',
      emailRuleMessageOne: 'Insira um email válido',
      emailRuleMessageTwo: 'Por favor informe o e-mail do cliente',
      emailPlaceholder: 'E-mail do cliente',
      personTypeLabel: 'Pessoa',
      personTypePlaceholder: 'Selecione o tipo',
      personTypeOptionOne: 'Pessoa Física',
      personTypeOptionTwo: 'Pessoa Jurídica',
      personTypeOptionThree: 'Estrangeiro',
      documentLabelOne: 'CPF',
      documentLabelTwo: 'Passaporte',
      documentRuleMessageOne: 'Por gentileza insira o CPF',
      documentRuleMessageTwo: 'Por gentileza insira um CPF Válido!',
      cnpjLabel: 'CNPJ',
      cnpjRuleMessageOne: 'Por favor digite o CNPJ',
      cnpjRuleMessageTwo: 'Por gentileza insira um CNPJ Válido!',
      rgLabel: 'RG',
      phoneLabel: 'Telefone',
      phoneRuleMessage: 'Insira um número válido.',
      phonePlaceholder: 'Telefone do Cliente',
      collaboratorIdLabel: 'Colaborador Responsável',
      collaboratorIdPlaceholder: 'Informe o Colaborador',
      parentNameLabel: 'Nome do cliente pai',
      parentNamePlaceholder: 'Nome do cliente pai',
      parentCnpjLabel: 'CNPJ do cliente pai',
      parentCnpjPlaceholder: 'CNPJ do cliente pai',
      parentCustomerTitle: 'Adicionar Cliente Principal',
      createContributor: 'Criar Colaborador',
    },
  },
  en: {
    NewClients: {
      randomEmailError: 'Fill in the name field to generate a random email',
      successMessage: 'Customer registered successfully!',
      errorMessage: 'Error registering customer.',
      cnpjInUseMessage:
        'This CNPJ is already in use, this does not prevent you from creating the client.',
      helmetTitle: 'New customer',
      pageHeaderTitle: 'New customer',
      cardTitle: 'Fill in customer data',
      keepRegisterText: 'Continue registering customers?',
      buttonText: 'Register',
      nameLabel: 'Name',
      nameRuleMessage: "Please enter the customer's name",
      namePlaceholder: 'Client name',
      emailLabel: 'Email',
      emailGenerateTooltip: 'Generate fake random email',
      emailRuleMessageOne: 'Enter a valid email',
      emailRuleMessageTwo: "Please provide the customer's email",
      emailPlaceholder: 'Customer email',
      personTypeLabel: 'Person',
      personTypePlaceholder: 'Select type',
      personTypeOptionOne: 'Physical person',
      personTypeOptionTwo: 'Legal peson',
      personTypeOptionThree: 'Foreign',
      documentLabelOne: 'CPF',
      documentLabelTwo: 'Passport',
      documentRuleMessageOne: 'Please enter your CPF',
      documentRuleMessageTwo: 'Please enter a Valid CPF!',
      cnpjLabel: 'CNPJ',
      cnpjRuleMessageOne: 'Please enter the CNPJ',
      cnpjRuleMessageTwo: 'Please enter a Valid CNPJ!',
      rgLabel: 'ID',
      phoneLabel: 'Phone',
      phoneRuleMessage: 'Please enter a valid number.',
      phonePlaceholder: 'Customer phone',
      collaboratorIdLabel: 'Responsible Employee',
      collaboratorIdPlaceholder: 'Inform the Employee',
      parentNameLabel: 'Parent customer name',
      parentNamePlaceholder: 'Parent customer name',
      parentCnpjLabel: 'Parent customer CNPJ',
      parentCnpjPlaceholder: 'Parent customer CNPJ',
      parentCustomerTitle: 'Add Main Customer',
      createContributor: 'Create Contributor',
    },
  },
  es: {
    NewClients: {
      randomEmailError:
        'Complete el campo de nombre para generar un correo electrónico aleatorio',
      successMessage: '¡Cliente registrado con éxito!',
      errorMessage: 'Error al registrar al cliente.',
      cnpjInUseMessage:
        'Este CNPJ ya está en uso, esto no impide crear el cliente.',
      helmetTitle: 'Nuevo cliente',
      pageHeaderTitle: 'Nuevo cliente',
      cardTitle: 'Rellene los datos del cliente',
      keepRegisterText: '¿Continuar registrando clientes?',
      buttonText: 'Registrar',
      nameLabel: 'Nombre',
      nameRuleMessage: 'Por favor ingrese el nombre del cliente',
      namePlaceholder: 'Nombre del cliente',
      emailLabel: 'Correo electrónico',
      emailGenerateTooltip: 'Generar correo electrónico aleatorio falso',
      emailRuleMessageOne: 'Introduzca un correo electrónico válido',
      emailRuleMessageTwo:
        'Por favor proporcione el correo electrónico del cliente',
      emailPlaceholder: 'Correo electrónico del cliente',
      personTypeLabel: 'Persona',
      personTypePlaceholder: 'Seleccione tipo',
      personTypeOptionOne: 'Persona Física',
      personTypeOptionTwo: 'Persona Jurídica',
      personTypeOptionThree: 'Extranjero',
      documentLabelOne: 'CPF',
      documentLabelTwo: 'Pasaporte',
      documentRuleMessageOne: 'Por favor ingrese su CPF',
      documentRuleMessageTwo: '¡Ingrese un CPF válido!',
      cnpjLabel: 'CNPJ',
      cnpjRuleMessageOne: 'Por favor ingrese el CNPJ',
      cnpjRuleMessageTwo: '¡Ingrese un CNPJ válido!',
      rgLabel: 'ID',
      phoneLabel: 'Teléfono',
      phoneRuleMessage: 'Por favor ingrese un número valido.',
      phonePlaceholder: 'Teléfono del cliente',
      collaboratorIdLabel: 'Empleado responsable',
      collaboratorIdPlaceholder: 'Informar al empleado',
      parentNameLabel: 'Nombre del cliente principal',
      parentNamePlaceholder: 'Nombre del cliente principal',
      parentCnpjLabel: 'CNPJ del cliente principal',
      parentCnpjPlaceholder: 'CNPJ del cliente principal',
      parentCustomerTitle: 'Agregar Cliente Principal',
      createContributor: 'Agregar Empleado',
    },
  },
}
