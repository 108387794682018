/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Col, Space, Modal, Tooltip } from 'antd'
import React, { Fragment, useState } from 'react'
import { useSystem } from '~/hooks/Maintenance/System/useSystem'
import { translate } from '~/utils/locale'

const CreateSystem: React.FC = () => {
  const { createSystem } = useSystem()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const [form] = Form.useForm()

  function onCreateSystem(valueForm: any) {
    try {
      const formatData = {
        name: valueForm.name,
        components: valueForm.components,
      }

      createSystem(formatData)

      form.resetFields()
      setIsModalVisible(false)
    } catch (err) {}
  }

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
        {translate('SystemCreate.modalTitle')}
      </Button>
      <Modal
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="650px"
        title={<b>{translate('SystemCreate.modalTitle')}</b>}
        footer={[
          <Space size="middle" key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalClient.closeButton')}
            </Button>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              form="createSystem"
              id="btn_create_system"
            >
              {translate('SystemCreate.saveButton')}
            </Button>
          </Space>,
        ]}
      >
        <Form
          id="createSystem"
          layout="vertical"
          onFinish={onCreateSystem}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label={translate('SystemCreate.nameLabel')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate('SystemCreate.nameRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('SystemCreate.namePlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Col>
            <Form.List name="components">
              {(fields, { add, remove }) => (
                <Row gutter={10}>
                  {fields.map(({ key, name, ...restField }) => (
                    <Fragment key={key}>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          label={
                            <Space>
                              <Tooltip
                                title={translate(
                                  'SystemCreate.DeleteComponent',
                                )}
                              >
                                <Button
                                  ghost
                                  shape="circle"
                                  icon={<DeleteOutlined />}
                                  onClick={() => remove(name)}
                                  size="small"
                                  danger
                                />
                              </Tooltip>
                              {translate('UpdateSystem.Component')}
                            </Space>
                          }
                          name={[name, 'name']}
                        >
                          <Input.TextArea
                            placeholder={translate(
                              'SystemCreate.componentPlaceholder',
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Fragment>
                  ))}
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {translate('UpdateSystem.AddComponent')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.List>
          </Col>
        </Form>
      </Modal>
    </>
  )
}

export default CreateSystem
