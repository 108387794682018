/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined } from '@ant-design/icons'
import {
  Modal,
  Button,
  Form,
  Input,
  Row,
  Col,
  Tooltip,
  Checkbox,
  Space,
} from 'antd'

import React, { useState } from 'react'

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import pt_BR from 'react-phone-number-input/locale/pt-BR.json'

import { useClientsContacts } from '~/hooks/Clients/useContactClients'
import { useListContacts } from '~/hooks/Clients/useListContacts'
import { translate } from '~/utils/locale'
import { Container, ContainerButton } from './styles'

interface ContactProps {
  description: string
  office: string
  is_responsible: boolean
  id: string
  contact: {
    name: string
    id: number
    email: string
    phone: string
  }
  customer: {
    id: string
    contact: {
      id: string
    }
  }
}

interface ContactPropsData {
  description: string
  office: string
  is_responsible: boolean
  id: string
  phone: string
}

interface UpdateContactProps {
  updateContacts: ContactProps
  isEdit: boolean
}

const UpdateContact: React.FC<UpdateContactProps> = ({
  updateContacts,
  isEdit = false,
}) => {
  const { updateContact } = useListContacts()
  const { updateContact: updateContactClients } = useClientsContacts()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [numberPhone, setNumberPhone] = useState('')
  // console.log(numberPhone);

  const initialData = {
    name: updateContacts.contact.name,
    email: updateContacts.contact.email,
    office: updateContacts.office,
    phone: updateContacts.contact.phone,
    description: updateContacts.description,
    is_responsible: updateContacts.is_responsible,
  }

  function onUpdateContact(valueForm: ContactPropsData) {
    try {
      if (valueForm.phone) {
        valueForm.phone = valueForm.phone.replace(/[^0-9]/g, '')
      }

      if (isEdit) {
        updateContactClients(
          valueForm,
          updateContacts.id,
          updateContacts.customer.id,
        )
      }
      updateContact(valueForm, updateContacts.id)
      setIsModalOpen(false)
    } catch (err) {
      setIsModalOpen(false)
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Container>
      <Tooltip title={translate('ClientsContactsTabUpdate.editTooltip')}>
        <Button
          type="primary"
          onClick={showModal}
          ghost
          shape="circle"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('ClientsContactsTabUpdate.modalTitle')}
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        style={{ top: 40 }}
        width="600px"
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('ClientsContactsTabUpdate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                form="onUpdateContacts"
                htmlType="submit"
                id="btn_save_contacts_client"
              >
                {translate('ClientsContactsTabUpdate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          id="onUpdateContacts"
          layout="vertical"
          initialValues={initialData}
          onFinish={onUpdateContact}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsTabUpdate.nameLabel')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate('ClientsContactsTabUpdate.nameRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'ClientsContactsTabUpdate.namePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsTabUpdate.emailLabel')}
                name="email"
                rules={[
                  {
                    required: true,
                    message: translate('ClientsContactsTabUpdate.emailRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'ClientsContactsTabUpdate.emailPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsTabUpdate.phoneLabel')}
                name="phone"
                rules={[
                  () => ({
                    validator(rule, phone) {
                      if (phone === undefined || phone === null) {
                        return Promise.resolve()
                      }
                      if (phone && isValidPhoneNumber(phone)) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          translate('ClientsContactsTabUpdate.phoneRule'),
                        ),
                      )
                    },
                  }),
                ]}
              >
                <PhoneInput
                  placeholder={translate(
                    'ClientsContactsTabUpdate.phonePlaceholder',
                  )}
                  labels={pt_BR}
                  defaultCountry="BR"
                  international
                  onChange={(e: any) => setNumberPhone(e.target?.value)}
                  value={numberPhone}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsTabUpdate.officeLabel')}
                name="office"
              >
                <Input
                  placeholder={translate(
                    'ClientsContactsTabUpdate.officePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Col span={16}>
            <Form.Item
              label={translate('ClientsContactsTabUpdate.responsibleLabel')}
              name="is_responsible"
              valuePropName="checked"
            >
              <Checkbox>
                {translate('ClientsContactsTabUpdate.responsibleCheckbox')}
              </Checkbox>
            </Form.Item>
          </Col>

          <Form.Item
            label={translate('ClientsContactsTabUpdate.descriptionLabel')}
            name="description"
          >
            <Input.TextArea
              placeholder={translate(
                'ClientsContactsTabUpdate.descriptionPlaceholder',
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  )
}

export default UpdateContact
