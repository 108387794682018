export default {
  pt: {
    UpdateSystem: {
      EditSystem: 'Editar Sistema',
      Details: 'Detalhes',
      SystemName: 'Nome do Sistema',
      DeleteComponent: 'Deletar componente',
      Component: 'Componentes',
      AddComponent: 'Adicionar Componente',
      Activity: 'Atividade',
      DeleteActivity: 'Deletar atividade',
      AddActivity: 'Adicionar Atividade',
    },
  },
  en: {
    UpdateSystem: {
      EditSystem: 'Edit Component',
      Details: 'Details',
      SystemName: 'System Name',
      DeleteComponent: 'Delete component',
      Component: 'Components',
      AddComponent: 'Add Component',
      Activity: 'Activity',
      DeleteActivity: 'Delete activity',
      AddActivity: 'Add Activity',
    },
  },
  es: {
    UpdateSystem: {
      EditSystem: 'Editar Sistema',
      Details: 'Detalles',
      SystemName: 'Nombre del Sistema',
      DeleteComponent: 'Eliminar componente',
      Component: 'Componentes',
      AddComponent: 'Agregar Componente',
      Activity: 'Actividad',
      DeleteActivity: 'Eliminar actividad',
      AddActivity: 'Agregar Actividad',
    },
  },
}
