/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import React, { useContext, createContext, useState } from 'react'

interface ClientProps {
  id?: string
  name: string
  contactId?: string
  parent_cnpj?: string
  valueTask?: number
  amountHours?: number
  amountHoursAdditional?: number
  numberKm?: number
  numberKmAdditional?: number
}

interface DataProps {
  id: string
  name: string
}

interface TeamProps {
  id: string
  name: string
  amount_to_receive?: number
}

interface TaskTypeProps {
  id: string
  title: string
  description: string
  duration: string
  valueTask: number
}

interface AddressProps {
  cep: string
  street: string
  number: number
  complement?: string
  neighborhood: string
  city: string
  id: string
  state: string
  location: { x: number; y: number }
}

interface TaskCreateContextData {
  dateTask?: dayjs.Dayjs
  setDateTask(value: any): void
  latitude?: any
  setLatitude(value: any): void
  longitude?: any
  setLongitude(value: any): void

  clientData?: ClientProps
  setClientData(value: ClientProps): void
  addressData?: AddressProps
  setAddressData(value: AddressProps): void
  scaleId?: DataProps
  setScaleId(value: DataProps): void
  taskTypeData?: TaskTypeProps
  setTaskTypeData(value: TaskTypeProps): void
  contributorData?: DataProps
  setContributorData(value: DataProps): void
  teamData?: TeamProps
  setTeamData(value: TeamProps): void

  description?: string
  setDescription(value: string): void
  externalCode?: string
  setExternalCode(value: string): void
  duration?: string
  setDuration(value: string): void
  priority?: string
  setPriority(value: string): void
  note?: string
  setNote(value: string): void
  number?: string
  setNumber(value: string): void

  type?: string
  setType(value: string): void

  amountHours?: number
  setAmountHours(value: number): void
  amountHoursAdditional?: number
  setAmountHoursAdditional(value?: number): void
  numberKm?: number
  setNumberKm(value: number): void
  numberKmAdditional?: number
  setNumberKmAdditional(value?: number): void

  saveAddressOnClient?: boolean
  setSaveAddressOnClient(value: boolean): void
  isSaveTask?: boolean
  setIsSaveTask(value: boolean): void
  isCreateTaskOnClient?: boolean
  setIsCreateTaskOnClient(value: boolean): void
}

const TaskCreateContext = createContext<TaskCreateContextData>(
  {} as TaskCreateContextData,
)

export const TaskCreateProvider: React.FC = ({ children }) => {
  const [isCreateTaskOnClient, setIsCreateTaskOnClient] = useState(false)

  const [latitude, setLatitude] = useState<any>(-14.235004)
  const [longitude, setLongitude] = useState<any>(-51.925282)

  const [clientData, setClientData] = useState<DataProps>({} as DataProps)
  const [addressData, setAddressData] = useState<AddressProps>(
    {} as AddressProps,
  )
  const [scaleId, setScaleId] = useState<DataProps>({} as DataProps)
  const [taskTypeData, setTaskTypeData] = useState<TaskTypeProps>(
    {} as TaskTypeProps,
  )
  const [contributorData, setContributorData] = useState<DataProps>(
    {} as DataProps,
  )
  const [teamData, setTeamData] = useState<TeamProps>({} as TeamProps)

  const [dateTask, setDateTask] = useState<any>(dayjs(new Date()))
  const [description, setDescription] = useState('')
  const [externalCode, setExternalCode] = useState('')
  const [duration, setDuration] = useState('')
  const [priority, setPriority] = useState('')
  const [note, setNote] = useState('')
  const [number, setNumber] = useState('')

  const [type, setType] = useState('')

  const [amountHours, setAmountHours] = useState(0)
  const [amountHoursAdditional, setAmountHoursAdditional] = useState<
    number | undefined
  >(0)
  const [numberKm, setNumberKm] = useState(0)
  const [numberKmAdditional, setNumberKmAdditional] = useState<
    number | undefined
  >(0)

  const [saveAddressOnClient, setSaveAddressOnClient] = useState(false)

  const [isSaveTask, setIsSaveTask] = useState(false)

  return (
    <TaskCreateContext.Provider
      value={{
        dateTask,
        setDateTask,
        latitude,
        setLatitude,
        longitude,
        setLongitude,
        clientData,
        setClientData,
        addressData,
        setAddressData,
        scaleId,
        setScaleId,
        taskTypeData,
        setTaskTypeData,
        contributorData,
        setContributorData,
        teamData,
        setTeamData,
        description,
        setDescription,
        externalCode,
        setExternalCode,
        duration,
        setDuration,
        priority,
        setPriority,
        note,
        setNote,
        number,
        setNumber,
        type,
        setType,
        amountHours,
        setAmountHours,
        amountHoursAdditional,
        setAmountHoursAdditional,
        numberKm,
        setNumberKm,
        numberKmAdditional,
        setNumberKmAdditional,
        saveAddressOnClient,
        setSaveAddressOnClient,
        isSaveTask,
        setIsSaveTask,
        isCreateTaskOnClient,
        setIsCreateTaskOnClient,
      }}
    >
      {children}
    </TaskCreateContext.Provider>
  )
}

export function useTaskCreate(): TaskCreateContextData {
  const context = useContext(TaskCreateContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
