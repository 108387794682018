import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import React from 'react'

import Text from 'antd/lib/typography/Text'

import { translate } from '~/utils/locale'
import { format } from 'date-fns'
import { ColumnsType } from 'antd/lib/table'
import { useDatabook } from '~/hooks/Maintenance/Databook/useDatabok'
import UpdateDatabookModal from '../Update'
// import DocumentsFilterModal from './Filter'
// import UpdateDocumentsModal from './Update'

interface DocumentsProps {
  _id: string
  name: string
  provided_responsible: string
  renovation_responsible: string
  inclusion_date: Date
  renovation_date: Date
  attchment: string
  is_exclusive: boolean
  plan_id: string
}

const DatabookTable: React.FC = () => {
  const { isLoading, databook, deleteDatabook } = useDatabook()

  const columns: ColumnsType<DocumentsProps> = [
    {
      title: translate('MaintenanceDocumentsTab.DocumentName'),
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceDocumentsTab.SupplyResponsibility'),
      key: 'provided_responsible',
      dataIndex: 'provided_responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceDocumentsTab.RenewalResponsibility'),
      key: 'renovation_responsible',
      dataIndex: 'renovation_responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceDocumentsTab.InclusionDate'),
      key: 'inclusion_date',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Text>
          {record.renovation_date
            ? format(new Date(record?.inclusion_date), 'dd/MM/yyyy')
            : 'Nenhuma data informada'}
        </Text>
      ),
    },
    {
      title: translate('MaintenanceDocumentsTab.RenewalDate'),
      key: 'renovation_date',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Text>
          {record.renovation_date
            ? format(new Date(record?.renovation_date), 'dd/MM/yyyy')
            : 'Nenhuma data informada'}
        </Text>
      ),
    },
    {
      title: translate('MaintenanceDocumentsTab.DocumentLink'),
      key: 'attchment',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <>
          {record.attchment ? (
            <Typography.Link href={record.attchment} target="_blank">
              {record.attchment}
            </Typography.Link>
          ) : (
            'Nenhum link informado'
          )}
        </>
      ),
    },
    {
      title: 'Exclusivo de algum plano?',
      key: 'is_exclusive',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Tag color={record.is_exclusive ? 'geekblue' : 'red'}>
          {record.is_exclusive ? 'Sim' : 'Não'}
        </Tag>
      ),
    },
    {
      title: translate('TasksRegistrationPauseTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <UpdateDatabookModal data={record} />
          <Popconfirm
            title={translate('MaintenanceDocumentsTab.ConfirmDeleteDocument')}
            onConfirm={() => deleteDatabook(record._id)}
          >
            <Tooltip
              title={translate('TasksRegistrationPauseTable.deleteTooltip')}
            >
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record._id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isLoading,
        size: 'large',
      }}
      size="small"
      scroll={{ x: 1000 }}
      columns={columns}
      dataSource={databook}
      pagination={{
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
      }}
    />
  )
}

export default DatabookTable
