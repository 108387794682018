/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import Text from 'antd/lib/typography/Text'

import { Popconfirm, Space, Table, Tooltip, Button } from 'antd'
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'

import { translate } from '~/utils/locale'
import { useActivity } from '~/hooks/Maintenance/Activity/useActivity'
import UpdateActivity from '../Update'

// import { Container } from './styles';

interface ActivityProps {
  _id: string
  title: string
  createdAt: string
  system: string
  component?: string
  activity?: string
  competence?: string
  periodicity: {
    type: string
    interval: number
    date: Date
    weekend: string
  }
  data_font: string
}

interface IActivityTable {
  data: ActivityProps[] | undefined
}

const ActivityTable: React.FC<IActivityTable> = ({ data }) => {
  const { deleteActivity, isLoading } = useActivity()

  const columns: ColumnsType<ActivityProps> = [
    // {
    //   title: 'ID Atividade',
    //   align: 'left',
    //   dataIndex: '_id',
    //   key: 'id',
    //   // defaultSortOrder: 'ascend',
    //   // sortDirections: ['ascend', 'descend', 'ascend'],
    //   // sorter: (a, b) => a.contact.name.localeCompare(b.contact.name),
    //   render: (_id: string) => (
    //     <Text copyable={{ text: _id }}>{_id.substring(0, 8)}</Text>
    //   ),
    // },
    {
      title: translate('MaintenanceActivity.Title'),
      key: 'title',
      dataIndex: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceActivity.SystemName'),
      key: 'system',
      dataIndex: 'system',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceActivity.SelectComponents'),
      key: 'component',
      dataIndex: 'component',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceActivity.Activities'),
      key: 'activity',
      dataIndex: 'activity',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceActivity.Responsibles'),
      dataIndex: 'competence',
      key: 'competence',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceActivity.Periodicity'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: ActivityProps) => (
        <>
          {record.periodicity.type === 'day' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Day')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Days')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.periodicity.type === 'sem' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Week')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Weeks')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.periodicity.type === 'men' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Months')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.MonthsPlural')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.periodicity.type === 'anu' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Year')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Years')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'Fonte de Dados',
      key: 'data_font',
      dataIndex: 'data_font',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('TasksRegistrationPauseTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <UpdateActivity dataActivity={record} />
          <Popconfirm
            title={translate('MaintenanceActivity.ConfirmDeleteActivity')}
            onConfirm={() => deleteActivity(record._id)}
          >
            <Tooltip
              title={translate('TasksRegistrationPauseTable.deleteTooltip')}
            >
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        rowKey={(record) => record._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={data}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default ActivityTable
