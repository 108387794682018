import React from 'react'

import { Container, ContainerCharts, ContainerMap } from './styles'
import CardsTasks from './Cards'
import TasksTotalLineChart from '../Charts/TasksTotalLineChart'
import TasksByValuePieChart from '../Charts/TasksByValuePieChart'
import TasksMapDashboard from '../Maps/Tasks'
import TasksByContributorAndValuePieChart from '../Charts/TasksByContributorAndValuePieChart'

import { useTaskWorking } from '~/hooks/Tasks/Kanban/useTaskWorking'
import { useTaskFinished } from '~/hooks/Tasks/Kanban/useTaskFinished'

import {
  CarOutlined,
  FileDoneOutlined,
  FileOutlined,
  LikeOutlined,
} from '@ant-design/icons'
import { useListTask } from '~/hooks/Tasks/useListTasks'
import { useTaskViewed } from '~/hooks/Tasks/Kanban/useTaskViewed'
import DashboardSkeleton from './skeleton'

const Initial: React.FC = () => {
  const {
    tasksViewedTotal,
    isLoading: isLoadingViewed,
    taskViewed,
  } = useTaskViewed()
  const {
    tasksWorkingTotal,
    isLoading: isLoadWork,
    taskWorking,
  } = useTaskWorking()
  const {
    tasksFinishedTotal,
    isLoading: isLoadFinish,
    taskFinished,
  } = useTaskFinished()
  const { tasksTotal, isLoading } = useListTask()

  if (
    isLoadWork ||
    !taskWorking ||
    isLoadFinish ||
    !taskFinished ||
    isLoadingViewed ||
    !taskViewed ||
    isLoading ||
    !tasksTotal
  ) {
    return <DashboardSkeleton />
  }

  const data = [
    {
      id: 1,
      backgroundColor: '#3C8ACB',
      iconBackgroundColor: '#5eacec',
      iconColor: 'white',
      titleColor: 'white',
      title: 'Total de Tarefas',
      value: tasksTotal,
      icon: <FileOutlined />,
    },
    {
      id: 2,
      backgroundColor: 'white',
      iconBackgroundColor: '#17EAD9',
      iconColor: 'white',
      titleColor: '#2b2929',
      title: 'Tarefas Visualizadas',
      value: tasksViewedTotal,
      icon: <FileDoneOutlined />,
    },
    {
      id: 3,
      backgroundColor: 'white',
      iconBackgroundColor: '#3969B7',
      iconColor: 'white',
      titleColor: '#2b2929',
      title: 'Tarefas A Caminho',
      value: tasksWorkingTotal,
      icon: <CarOutlined />,
    },
    {
      id: 4,
      backgroundColor: 'white',
      iconBackgroundColor: '#585555',
      iconColor: 'white',
      titleColor: '#2b2929',
      title: 'Tarefas Finalizadas',
      value: tasksFinishedTotal,
      icon: <LikeOutlined />,
    },
  ]

  return (
    <Container>
      <CardsTasks data={data} />

      <ContainerCharts>
        <TasksTotalLineChart type="dash" />
        <TasksByValuePieChart type="dash" />
      </ContainerCharts>

      <ContainerMap>
        <TasksMapDashboard type={false} />
        <TasksByContributorAndValuePieChart type="dash" />
      </ContainerMap>
    </Container>
  )
}

export default Initial
