import React from 'react'
import { Skeleton, Row, Col, Card } from 'antd'

const DashboardSkeleton: React.FC = () => {
  return (
    <div style={{ padding: '24px' }}>
      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Skeleton active title={false} paragraph={{ rows: 2 }} />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Skeleton active title={false} paragraph={{ rows: 2 }} />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Skeleton active title={false} paragraph={{ rows: 2 }} />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Skeleton active title={false} paragraph={{ rows: 2 }} />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '24px' }}>
        <Col span={12}>
          <Card>
            <Skeleton active title={false} paragraph={{ rows: 5 }} />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Skeleton active title={false} paragraph={{ rows: 5 }} />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '24px' }}>
        <Col span={12}>
          <Card>
            <Skeleton active title={false} paragraph={{ rows: 6 }} />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Skeleton active title={false} paragraph={{ rows: 6 }} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default DashboardSkeleton
