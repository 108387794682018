/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Space, Typography, Table } from 'antd'

import React, { useState } from 'react'

import { translate } from '~/utils/locale'

import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { ColumnsType } from 'antd/lib/table'
import { format } from 'date-fns'
import { useDatabook } from '~/hooks/Maintenance/Databook/useDatabok'

interface DocumentsProps {
  _id: string
  name: string
  provided_responsible: string
  renovation_responsible: string
  inclusion_date: Date
  renovation_date: Date
  attchment: string
  is_exclusive: boolean
  plan_id: string
}

const AddDocumentsModal: React.FC = () => {
  const { addDocument, maintenance } = useShowMaintenance()
  const { isLoading, databook } = useDatabook()

  const [documentId, setDocumentId] = useState<React.Key[]>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const columns: ColumnsType<DocumentsProps> = [
    {
      title: translate('MaintenanceDocumentsTab.DocumentName'),
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceDocumentsTab.SupplyResponsibility'),
      key: 'provided_responsible',
      dataIndex: 'provided_responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceDocumentsTab.RenewalResponsibility'),
      key: 'renovation_responsible',
      dataIndex: 'renovation_responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceDocumentsTab.InclusionDate'),
      key: 'inclusion_date',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Typography.Text>
          {record.renovation_date
            ? format(new Date(record?.inclusion_date), 'dd/MM/yyyy')
            : 'Nenhuma data informada'}
        </Typography.Text>
      ),
    },
    {
      title: translate('MaintenanceDocumentsTab.RenewalDate'),
      key: 'renovation_date',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Typography.Text>
          {record.renovation_date
            ? format(new Date(record?.renovation_date), 'dd/MM/yyyy')
            : 'Nenhuma data informada'}
        </Typography.Text>
      ),
    },
    {
      title: translate('MaintenanceDocumentsTab.DocumentLink'),
      key: 'attchment',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <>
          {record.attchment ? (
            <Typography.Link href={record.attchment} target="_blank">
              {record.attchment}
            </Typography.Link>
          ) : (
            'Nenhum link informado'
          )}
        </>
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys: documentId,
    onChange: (selectedRowKeys: React.Key[]) => {
      setDocumentId(selectedRowKeys)
    },
    getCheckboxProps: (record: DocumentsProps) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  const filteredData = maintenance?._id
    ? databook?.filter((item) => item.plan_id === maintenance._id)
    : databook

  const displayData = filteredData!.length > 0 ? filteredData : databook

  function AddDocument() {
    const documentsId = documentId.map((e) => {
      return { id: e }
    })

    const data = {
      documents: documentsId,
    }
    try {
      addDocument(data)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  return (
    <>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={showModal}
        style={{ width: '100%' }}
        type="primary"
      >
        {translate('MaintenanceDocumentsTab.AddDocuments')}
      </Button>

      <Modal
        title={translate('MaintenanceDocumentsTab.AddDocuments')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="1000px"
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalClient.closeButton')}
            </Button>
            {documentId.length !== 0 ? (
              <Button type="primary" onClick={AddDocument}>
                {translate('MaintenanceDocumentsTab.Save')}
              </Button>
            ) : (
              ''
            )}
          </Space>,
        ]}
      >
        <Table
          rowKey={(record) => record?._id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isLoading,
            size: 'large',
          }}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          size="small"
          scroll={{ x: 1000 }}
          columns={columns}
          dataSource={displayData}
          pagination={{
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          }}
        />
      </Modal>
    </>
  )
}

export default AddDocumentsModal
