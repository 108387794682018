/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import cep from 'cep-promise'
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'
import { LatLngExpression } from 'leaflet'

import {
  Modal,
  Form,
  Button,
  Input,
  Row,
  Col,
  Radio,
  Select,
  Tooltip,
  message,
  Checkbox,
  Space,
} from 'antd'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { MaskedInput } from 'antd-mask-input'
import { ValidateStatus } from 'antd/lib/form/FormItem'

import api from '~/services/api'
import geolocationApi from '~/services/geolocationApi'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

import { ContainerButton } from './styles'

interface NewAddressData {
  zip_code: string
  type: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  notes: string
  location: string
  urlGoogle?: string
}

interface Addresses {
  city: string
  complement: string
  id: string
  location: {
    x: number
    y: number
  }
  neighborhood: string
  number: string
  state: string
  street: string
  zip_code: string
}

interface AddressInfoProps {
  address: Addresses
  id: string
  notes: string
  type: string
}

interface AddressFormProps {
  AddressInfo?: AddressInfoProps
  isUpdate?: boolean
  refetch: () => void
}

interface MapUpdateFunctionProps {
  center: LatLngExpression
  zoom: number
}

const AddressModal: React.FC<AddressFormProps> = ({
  AddressInfo = {} as AddressInfoProps,
  isUpdate = false,
  refetch,
}) => {
  const [addressEditData, setAddressEditData] = useState({})

  const [latitude, setLatitude] = useState<any>(-14.235004)
  const [longitude, setLongitude] = useState<any>(-51.925282)

  const [zoomLevel, setZoomLevel] = useState(3)

  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [street, setStreet] = useState('')

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)
  const [mapSearch, setMapSearch] = useState(false)
  const [mapValidate, setMapValidate] = useState(false)
  const [hidden, setHidden] = useState(true)

  const [findingCEPStatus, setFindingCEPStatus] = useState('' as ValidateStatus)

  const { contactId } = useParams<{ contactId: string }>()

  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [form] = Form.useForm()

  const { TextArea } = Input

  const formatComma = (value: string, field: 'latitude' | 'longitude') => {
    const valueFormatted = value.replace(/,/g, '.')

    if (
      valueFormatted !== '-' &&
      valueFormatted !== '.' &&
      valueFormatted !== '-.'
    ) {
      if (field === 'latitude') {
        setLatitude(valueFormatted)
      } else if (field === 'longitude') {
        setLongitude(valueFormatted)
      }
    }
  }

  const stateOptions = [
    { label: 'AC', value: 'AC' },
    { label: 'AL', value: 'AL' },
    { label: 'AP', value: 'AP' },
    { label: 'AM', value: 'AM' },
    { label: 'BA', value: 'BA' },
    { label: 'CE', value: 'CE' },
    { label: 'ES', value: 'ES' },
    { label: 'GO', value: 'GO' },
    { label: 'MA', value: 'MA' },
    { label: 'MT', value: 'MT' },
    { label: 'MS', value: 'MS' },
    { label: 'MG', value: 'MG' },
    { label: 'PA', value: 'PA' },
    { label: 'PB', value: 'PB' },
    { label: 'PR', value: 'PR' },
    { label: 'PE', value: 'PE' },
    { label: 'PI', value: 'PI' },
    { label: 'RJ', value: 'RJ' },
    { label: 'RN', value: 'RN' },
    { label: 'RS', value: 'RS' },
    { label: 'RO', value: 'RO' },
    { label: 'RR', value: 'RR' },
    { label: 'SC', value: 'SC' },
    { label: 'SP', value: 'SP' },
    { label: 'SE', value: 'SE' },
    { label: 'TO', value: 'TO' },
    { label: 'DF', value: 'DF' },
  ]

  const initialValues = {
    type: 'business',
  }

  useEffect(() => {
    if (AddressInfo.id) {
      setMapValidate(true)
      setAddressEditData({
        zip_code: AddressInfo.address.zip_code,
        type: AddressInfo.type,
        street: AddressInfo.address.street,
        number: AddressInfo.address.number,
        complement: AddressInfo.address.complement,
        neighborhood: AddressInfo.address.neighborhood,
        city: AddressInfo.address.city,
        state: AddressInfo.address.state,
        notes: AddressInfo.notes,
        latitude: AddressInfo.address.location.x,
        longitude: AddressInfo.address.location.y,
      })

      setState(AddressInfo.address.state)
      setStreet(AddressInfo.address.street)
      setCity(AddressInfo.address.city)
      setZoomLevel(17)

      if (AddressInfo.address.location) {
        setLatitude(AddressInfo.address.location.x)
        setLongitude(AddressInfo.address.location.y)
      }
    }
  }, [AddressInfo])

  useEffect(() => {
    if (latitude !== -14.235004 && longitude !== -51.925282) {
      setZoomLevel(17)
    }
  }, [latitude, longitude])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setLatitude(-14.235004)
    setLongitude(-51.925282)
    setFindingCEPStatus('' as ValidateStatus)
    setCity('')
    setState('')
    setStreet('')
    setZoomLevel(3)
    setIsModalVisible(false)
    setMapValidate(false)
    setHidden(true)
  }

  const onCreate = useCallback(
    async (data: NewAddressData) => {
      try {
        setConfirmLoading(true)

        data.zip_code = data.zip_code.replace(/[^0-9]/g, '')

        data.location = `(${latitude}, ${longitude})`

        const apiCreateResponse = await api.post(
          `/company/${selectedCompany}/address`,
          {
            zip_code: data.zip_code,
            street: data.street,
            number: data.number,
            complement: data.complement,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            location: data.location,
          },
        )

        await api.post(
          `/company/${selectedCompany}/contact/${contactId}/address/${apiCreateResponse.data.id}`,
          {
            type: data.type,
            notes: data.notes,
          },
        )

        message.success(translate('AddressModal.createSuccessMessage'))

        refetch()

        form.resetFields()

        setLatitude(-14.235004)
        setLongitude(-51.925282)
        setMapValidate(false)
        setFindingCEPStatus('' as ValidateStatus)
        setCity('')
        setState('')
        setStreet('')
        setZoomLevel(3)
        setConfirmLoading(false)

        if (!keepRegister) {
          setIsModalVisible(false)
        }
      } catch (err: any) {
        ShowError(
          err.message,
          translate('AddressModal.createErrorMessage'),
          locale,
        )

        setConfirmLoading(false)
      }
    },
    [
      selectedCompany,
      contactId,
      form,
      latitude,
      longitude,
      refetch,
      keepRegister,
      locale,
    ],
  )

  const onUpdate = useCallback(
    async (data: NewAddressData) => {
      try {
        setConfirmLoading(true)

        data.zip_code = data.zip_code.replace(/[^0-9]/g, '')
        data.location = `(${latitude}, ${longitude})`

        await api.put(
          `/company/${selectedCompany}/address/${AddressInfo.address.id}`,
          {
            zip_code: data.zip_code,
            street: data.street,
            number: data.number,
            complement: data.complement,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            location: data.location,
          },
        )

        await api.put(
          `/company/${selectedCompany}/contact/${contactId}/address/${AddressInfo.address.id}`,
          {
            type: data.type,
            notes: data.notes,
          },
        )

        message.success(translate('AddressModal.updateSuccessMessage'))

        setFindingCEPStatus('' as ValidateStatus)
        refetch()
        setMapValidate(false)
        setConfirmLoading(false)
        setIsModalVisible(false)
      } catch (err: any) {
        ShowError(
          err.message,
          translate('AddressModal.updateErrorMessage'),
          locale,
        )

        setConfirmLoading(false)
      }
    },
    [
      selectedCompany,
      contactId,
      AddressInfo,
      latitude,
      longitude,
      refetch,
      locale,
    ],
  )

  const searchCEP = useCallback(
    async (data: string) => {
      try {
        setFindingCEPStatus('validating')
        const cepInfo = await cep(data)

        form.setFieldsValue({
          state: cepInfo.state,
          city: cepInfo.city,
          neighborhood: cepInfo.neighborhood,
          street: cepInfo.street,
        })

        setState(cepInfo.state)
        setCity(cepInfo.city)
        setStreet(cepInfo.street)

        setMapValidate(false)
        setFindingCEPStatus('success')
      } catch (error) {
        message.error(translate('AddressModal.searchZipcodeErrorMessage'))
        form.resetFields()
        setState('')
        setCity('')
        setStreet('')
        setFindingCEPStatus('error')
      }
    },
    [form],
  )

  const searchMap = useCallback(async () => {
    try {
      setMapSearch(true)
      const params = {
        street,
        city,
        state,
        format: 'json',
      }

      const { data } = await geolocationApi.get('search', { params })

      if (data.length > 0) {
        setHidden(true)
        form.setFieldsValue({
          latitude: data[0].lat,
          longitude: data[0].lon,
        })

        setLatitude(data[0].lat)
        setLongitude(data[0].lon)
        setZoomLevel(17)
      } else {
        setHidden(false)

        form.setFieldsValue({
          latitude: '',
          longitude: '',
        })

        message.warning(translate('AddressModal.geoSearchWarningMessage'), 10)
      }

      setMapValidate(true)
      setMapSearch(false)
    } catch (error) {
      message.error(translate('AddressModal.geoSearchErrorMessage'))
      setMapValidate(true)
      setMapSearch(false)
    }
  }, [form, city, state, street])

  function ChangeView({ center, zoom }: MapUpdateFunctionProps) {
    const map = useMap()
    map.setView(center, zoom)
    return null
  }

  return (
    <>
      {isUpdate ? (
        <Tooltip title={translate('AddressModal.editTooltipText')}>
          <Button
            ghost
            shape="circle"
            icon={<EditOutlined />}
            onClick={showModal}
          />
        </Tooltip>
      ) : (
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={showModal}
        >
          {translate('AddressModal.createButtonText')}
        </Button>
      )}
      <Modal
        width={1200}
        title={
          isUpdate
            ? translate('AddressModal.modalUpdateTitle')
            : translate('AddressModal.modalCreateTitle')
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Space key="buttons">
            {!isUpdate && (
              <Checkbox
                onChange={(e) => {
                  setKeepRegister(e.target.checked)
                }}
                checked={keepRegister}
              >
                {translate('AddressModal.keepRegisterText')}
              </Checkbox>
            )}
            <Button key="back" onClick={handleCancel}>
              {translate('AddressModal.cancelButtonText')}
            </Button>
            <ContainerButton>
              <Button
                key="submit"
                type="primary"
                loading={confirmLoading}
                onClick={handleOk}
                id="btn_create_address"
              >
                {isUpdate
                  ? translate('AddressModal.updateFinishButtonText')
                  : translate('AddressModal.createFinishButtonText')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Row>
          <Col span={12}>
            <Form
              form={form}
              onFinish={isUpdate ? onUpdate : onCreate}
              initialValues={addressEditData || initialValues}
              layout="vertical"
            >
              <Row gutter={14}>
                <Col span={12}>
                  <Form.Item
                    label={translate('AddressModal.typeLabel')}
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: translate('AddressModal.typeRule'),
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        form.setFieldValue('type', e.target.value)
                      }}
                    >
                      <Radio.Button value="personal">
                        {translate('AddressModal.typeOptionOne')}
                      </Radio.Button>
                      <Radio.Button value="business">
                        {translate('AddressModal.typeOptionTwo')}
                      </Radio.Button>
                      <Radio.Button value="billing">
                        {translate('AddressModal.typeOptionThree')}
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    label={translate('AddressModal.zipcodeLabel')}
                    name="zip_code"
                    hasFeedback
                    validateStatus={findingCEPStatus}
                    rules={[
                      {
                        required: true,
                        message: translate('AddressModal.zipcodeRule'),
                      },
                    ]}
                  >
                    <MaskedInput
                      onChange={(e) => {
                        const cepInput = e.target.value.replace(/\D/g, '')
                        if (cepInput.length === 8) {
                          searchCEP(e.target.value)
                        }
                      }}
                      mask={'00000-000'}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <a
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Form.Item
                      label={translate('AddressModal.zipcodeHelpLabel')}
                    >
                      <Button type="primary">
                        {translate('AddressModal.zipcodeHelpText')}
                      </Button>
                    </Form.Item>
                  </a>
                </Col>
              </Row>

              <Row gutter={14}>
                <Col span={4}>
                  <Form.Item
                    label={translate('AddressModal.stateLabel')}
                    name="state"
                  >
                    <Select
                      style={{ width: '100%' }}
                      className="menu_input"
                      optionFilterProp="children"
                      onChange={(e) => setState(String(e))}
                      options={stateOptions}
                    />
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item
                    label={translate('AddressModal.cityLabel')}
                    name="city"
                  >
                    <Input onChange={(e) => setCity(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item
                    label={translate('AddressModal.neighborhoodLabel')}
                    name="neighborhood"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={14}>
                <Col span={18}>
                  <Form.Item
                    label={translate('AddressModal.streetLabel')}
                    name="street"
                  >
                    <Input onChange={(e) => setStreet(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    label={translate('AddressModal.numberLabel')}
                    name="number"
                    rules={[
                      {
                        required: true,
                        message: translate('AddressModal.numberRule'),
                      },
                    ]}
                  >
                    <Input maxLength={5} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={14}>
                <Col span={12}>
                  <Form.Item
                    label={translate('AddressModal.complementLabel')}
                    name="complement"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={translate('AddressModal.mapLabel')}
                    name="mapa"
                    rules={[
                      {
                        required: true,
                        validator: () => {
                          if (mapValidate) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            translate('AddressModal.mapRule'),
                          )
                        },
                      },
                    ]}
                  >
                    <Button
                      key="submit"
                      type="primary"
                      disabled={
                        !!(state === '' || street === '' || city === '')
                      }
                      loading={mapSearch}
                      onClick={searchMap}
                    >
                      {translate('AddressModal.mapButtonText')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={14}>
                <Col span={8}>
                  <Form.Item
                    hidden={hidden}
                    label={translate('AddressModal.latitudeLabel')}
                    name="latitude"
                    initialValue={latitude}
                    rules={[
                      {
                        required: !hidden,
                        message: translate('AddressModal.latitudeRule'),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => formatComma(e.target.value, 'latitude')}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    hidden={hidden}
                    label={translate('AddressModal.longitudeLabel')}
                    name="longitude"
                    initialValue={longitude}
                    rules={[
                      {
                        required: !hidden,
                        message: translate('AddressModal.longitudeRule'),
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => formatComma(e.target.value, 'longitude')}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <a
                    href="https://www.latlong.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Form.Item
                      label={translate('AddressModal.latlongButtonLabel')}
                      hidden={hidden}
                    >
                      <Button type="primary">
                        {translate('AddressModal.latlongButtonText')}
                      </Button>
                    </Form.Item>
                  </a>
                </Col>
              </Row>

              <Row gutter={14}>
                <Col span={24}>
                  <Form.Item
                    label={translate('AddressModal.observationLabel')}
                    name="notes"
                  >
                    <TextArea rows={2} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            span={12}
            style={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <MapContainer
              center={[latitude, longitude]}
              zoom={zoomLevel}
              scrollWheelZoom={false}
              style={{ height: '450px', width: '450px' }}
            >
              <ChangeView center={[latitude, longitude]} zoom={zoomLevel} />
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {latitude !== -14.235004 && longitude !== -51.925282 && (
                <Marker position={[latitude, longitude]} />
              )}
            </MapContainer>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default AddressModal
