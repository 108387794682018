import React from 'react'
import CreateMaintenanceComponent from '~/components/DataManipulation/Maintenance/Create'
import { ActivityProvider } from '~/hooks/Maintenance/Activity/useActivity'
import { SystemProvider } from '~/hooks/Maintenance/System/useSystem'

import { ListMaintenanceProvider } from '~/hooks/Maintenance/useMaintenance'
import { ClientsListTaskProvider } from '~/hooks/Tasks/Create/clientsListTask'
import { ContributorsTaskProvider } from '~/hooks/Tasks/Create/useContributorTask'

// import { Container } from './styles';

const CreateMaintenance: React.FC = () => {
  return (
    <ListMaintenanceProvider>
      <ClientsListTaskProvider>
        <ContributorsTaskProvider>
          <SystemProvider>
            <ActivityProvider>
              <CreateMaintenanceComponent />
            </ActivityProvider>
          </SystemProvider>
        </ContributorsTaskProvider>
      </ClientsListTaskProvider>
    </ListMaintenanceProvider>
  )
}

export default CreateMaintenance
