/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { translate } from '~/utils/locale'
import SystemModalMaintenance from '../Create/Modal'
import { useActivity } from '~/hooks/Maintenance/Activity/useActivity'
import dayjs from 'dayjs'

import { Container } from '../Create/styles'

interface ActivityProps {
  _id: string
  title: string
  createdAt: string
  component?: string
  system: string
  activity?: string
  competence: string
  periodicity: {
    type: string
    interval: number
    date: Date
    weekend: string
  }
  data_font: string
}

interface Activity {
  dataActivity: ActivityProps
}

const UpdateActivity: React.FC<Activity> = ({ dataActivity }) => {
  const { updateActivity, systemData } = useActivity()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
    const regex = /^[0-9]*$/

    if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault()
    }
  }

  const [form] = Form.useForm()

  const options = [
    { label: 'Sábado e Domingo', value: 'weekend' },
    { label: 'Somente Sábado', value: 'saturday' },
    { label: 'Somente Domingo', value: 'sunday' },
  ]

  useEffect(() => {
    if (systemData?.id) {
      form.setFieldsValue({
        system: systemData?.name,
        component: systemData?.component,
      })
    }
  }, [form, systemData])

  const initialValues = {
    title: dataActivity.title,
    component: dataActivity.component,
    system: dataActivity.system,
    data_font: dataActivity.data_font,
    activity: dataActivity.activity,
    type: dataActivity.periodicity.type,
    interval: dataActivity.periodicity.interval,
    date: dayjs(dataActivity.periodicity.date),
    competence: dataActivity.competence,
    weekend: dataActivity.periodicity.weekend,
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  function UpdateActivity(valueForm: any) {
    try {
      const formatData = {
        title: valueForm.title,
        system: valueForm.system,
        component: valueForm.component,
        activity: valueForm.activity,
        competence: valueForm.competence,
        data_font: valueForm.data_font,
        periodicity: {
          type: valueForm.type,
          interval: valueForm.interval,
          weekend: valueForm.weekend,
        },
      }

      updateActivity(formatData, dataActivity._id)

      setIsModalVisible(false)

      form.resetFields()
    } catch (err) {}
  }

  return (
    <>
      <Tooltip title="Editar Atividade">
        <Button
          onClick={showModal}
          ghost
          shape="circle"
          type="primary"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title="Editar Atividade"
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,
          maxHeight: 'calc(100vh - 100px)',
        }}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalClient.closeButton')}
            </Button>
            <Button
              type="primary"
              key="update"
              htmlType="submit"
              form="update_activity"
            >
              {translate('TasksRegistrationPauseUpdate.saveButton')}
            </Button>
          </Space>,
        ]}
      >
        <Form
          onFinish={UpdateActivity}
          form={form}
          layout="vertical"
          id="update_activity"
          initialValues={initialValues}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceActivity.Title')}
                name="title"
              >
                <Input placeholder={translate('MaintenanceActivity.TitleP')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    <p>Equipe/Competência</p>
                  </Space>
                }
                name="competence"
              >
                <Input placeholder="Informe a equipe/competência" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    <p>{translate('MaintenanceCreate.Component')}</p>

                    {dataActivity?.component ? (
                      <SystemModalMaintenance type="edit" />
                    ) : (
                      ''
                    )}
                  </Space>
                }
                name="component"
                rules={[
                  {
                    required: true,
                    message: 'Informe o componente',
                  },
                ]}
              >
                {dataActivity?.component ? (
                  <Input />
                ) : (
                  <SystemModalMaintenance type="edit" />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceCreate.System')}
                name="system"
              >
                <Input
                  placeholder={translate('SystemCreate.componentPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Fonte de Dados" name="data_font">
                <Input
                  placeholder={translate('SystemCreate.componentPlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceCreate.Activity')}
                name="activity"
              >
                <Input.TextArea
                  placeholder={translate('MaintenanceCreate.AddActivity')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceCreate.Type')}
                name="type"
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.TypePlaceholder')}
                >
                  <Select.Option value="day">
                    {translate('MaintenanceCreate.Daily')}
                  </Select.Option>
                  <Select.Option value="sem">
                    {translate('MaintenanceCreate.Weekly')}
                  </Select.Option>
                  <Select.Option value="men">
                    {translate('MaintenanceCreate.Monthly')}
                  </Select.Option>
                  <Select.Option value="anu">
                    {translate('MaintenanceCreate.Yearly')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceCreate.Interval')}
                name="interval"
              >
                <InputNumber
                  onKeyPress={handleKeyPress}
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.EnterInterval')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {dataActivity.periodicity.type === 'day' &&
            dataActivity.periodicity.interval === 1 ? (
              <Row>
                <Form.Item
                  label="Atividades aos Finais de Semana"
                  name="weekend"
                >
                  <Radio.Group optionType="button" options={options} />
                </Form.Item>
              </Row>
            ) : (
              ''
            )}
          </Row>

          <Container>
            {dataActivity.periodicity.type === 'day' ? (
              <>
                {dataActivity.periodicity.interval === 1 ? (
                  <>
                    {dataActivity.periodicity.weekend === 'weekend' ? (
                      <Tag className="tag" color="geekblue">
                        Atividade Diária - A cada{' '}
                        {dataActivity.periodicity.interval} dia e aos finais de
                        semana <br />
                      </Tag>
                    ) : (
                      ''
                    )}

                    {dataActivity.periodicity.weekend === 'saturday' ? (
                      <Tag className="tag" color="geekblue">
                        Atividade Diária - A cada{' '}
                        {dataActivity.periodicity.interval} dia e aos sábados
                        <br />
                      </Tag>
                    ) : (
                      ''
                    )}

                    {dataActivity.periodicity.weekend === 'sunday' ? (
                      <Tag className="tag" color="geekblue">
                        Atividade Diária - A cada{' '}
                        {dataActivity.periodicity.interval} dia e aos domingos
                        <br />
                      </Tag>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <Tag className="tag" color="geekblue">
                    Atividade Diária - A cada{' '}
                    {dataActivity.periodicity.interval} dias <br />
                  </Tag>
                )}
              </>
            ) : (
              ''
            )}

            {dataActivity.periodicity.type === 'sem' ? (
              <>
                {dataActivity.periodicity.interval === 1 ? (
                  <Tag className="tag" color="purple">
                    Atividade Semanal - A cada uma semana <br />
                  </Tag>
                ) : (
                  <Tag className="tag" color="purple">
                    Atividade Semanal - A cada{' '}
                    {dataActivity.periodicity.interval} semanas <br />
                  </Tag>
                )}
              </>
            ) : (
              ''
            )}

            {dataActivity.periodicity.type === 'men' ? (
              <>
                {dataActivity.periodicity.interval === 1 ? (
                  <Tag className="tag" color="green">
                    Atividade Mensal - A cada um mês <br />
                  </Tag>
                ) : (
                  <Tag className="tag" color="green">
                    Atividade Mensal - A cada{' '}
                    {dataActivity.periodicity.interval} meses <br />
                  </Tag>
                )}
              </>
            ) : (
              ''
            )}

            {dataActivity.periodicity.type === 'anu' ? (
              <>
                {dataActivity.periodicity.interval === 1 ? (
                  <Tag className="tag" color="magenta">
                    Atividade Anual - A cada um ano <br />
                  </Tag>
                ) : (
                  <Tag className="tag" color="magenta">
                    Atividade Anual - A cada {dataActivity.periodicity.interval}{' '}
                    anos <br />
                  </Tag>
                )}
              </>
            ) : (
              ''
            )}
          </Container>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateActivity
