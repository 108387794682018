/* eslint-disable react-hooks/exhaustive-deps */
import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Button, Card, Input, Space } from 'antd'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useActivity } from '~/hooks/Maintenance/Activity/useActivity'
import { translate } from '~/utils/locale'

import Text from 'antd/lib/typography/Text'
import ActivityTable from './Table'
import ActivityFilterModal from './Filter'

// import { Container } from './styles';

const ActivityBoard: React.FC = () => {
  const { activity, activityTotal, isLoading, isFetching } = useActivity()

  const [searchActivity, setSearchActivity] = useState<string>('')

  const lowerBusca = searchActivity.toString().toLowerCase()

  const activityData = useMemo(() => {
    return activity?.filter(
      (datas) => datas.activity?.toString().toLowerCase().includes(lowerBusca),
    )
  }, [searchActivity, activity])

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('MaintenanceActivity.Activities')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {activityTotal !== undefined ? (
                <>
                  {activityTotal === 1
                    ? `${translate(
                        'MaintenanceActivity.TotalOf',
                      )} ${activityTotal} ${translate(
                        'MaintenanceActivity.Activity',
                      )}`
                    : `${translate(
                        'MaintenanceActivity.TotalOf',
                      )} ${activityTotal} ${translate(
                        'MaintenanceActivity.ActivityT',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <Input
            placeholder={translate('MaintenanceActivity.SearchActivity')}
            style={{ width: '100%' }}
            value={searchActivity}
            size="large"
            onChange={(e) => setSearchActivity(e.target.value)}
            prefix={<SearchOutlined />}
          />
          <ActivityFilterModal />
          <Link to="/atividades/novo">
            <Button type="primary" icon={<PlusOutlined />}>
              {translate('MaintenanceActivity.NewActivity')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <ActivityTable data={searchActivity ? activityData : activity} />
    </Card>
  )
}

export default ActivityBoard
