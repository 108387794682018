export default {
  pt: {
    MaintenanceDocumentsTab: {
      AddDocuments: 'Adicionar Documentos',
      DocumentName: 'Nome do Documento',
      EnterDocumentName: 'Informe o nome do documento',
      DocumentLink: 'Link Anexo',
      EnterDocumentLink: 'Informe o link do anexo',
      SupplyResponsibility: 'Responsabilidade pelo Fornecimento',
      EnterSupplyResponsibility: 'Informe a responsabilidade pelo Fornecimento',
      RenewalResponsibility: 'Responsibilidade pela Renovação',
      EnterRenewalResponsibility: 'Informe a responsibilidade pela Renovação',
      InclusionDate: 'Data de Inclusão',
      RenewalDate: 'Data de Renovação',

      Save: 'Salvar',
      ConfirmDeleteDocument: 'Deseja deletar o documento?',

      Filters: 'Filtros',
      AppliedFilters: 'Filtros Aplicados',
      DocumentFilters: 'Filtros de Documentos',
      Apply: 'Aplicar',
      Clear: 'Limpar',
    },
  },
  en: {
    MaintenanceDocumentsTab: {
      AddDocuments: 'Add Documents',
      DocumentName: 'Document Name',
      DocumentLink: 'Link Attachment',
      EnterDocumentLink: 'Inform the attachment link',
      EnterDocumentName: 'Enter the document name',
      SupplyResponsibility: 'Supply Responsibility',
      EnterSupplyResponsibility: 'Enter the supply responsibility',
      RenewalResponsibility: 'Renewal Responsibility',
      EnterRenewalResponsibility: 'Enter the renewal responsibility',
      InclusionDate: 'Inclusion Date',
      RenewalDate: 'Renewal Date',
      Save: 'Save',
      ConfirmDeleteDocument: 'Do you want to delete the document?',

      Filters: 'Filters',
      AppliedFilters: 'Applied Filters',
      DocumentFilters: 'Document Filters',
      Apply: 'Apply',
      Clear: 'Clear',
    },
  },
  es: {
    MaintenanceDocumentsTab: {
      AddDocuments: 'Agregar Documentos',
      DocumentName: 'Nombre del Documento',
      DocumentLink: 'Enlace adjunto',
      EnterDocumentLink: 'Informe el enlace adjunto',
      EnterDocumentName: 'Informe el nombre del documento',
      SupplyResponsibility: 'Responsabilidad por el Suministro',
      EnterSupplyResponsibility: 'Informe la responsabilidad por el Suministro',
      RenewalResponsibility: 'Responsabilidad por la Renovación',
      EnterRenewalResponsibility:
        'Informe la responsabilidad por la Renovación',
      InclusionDate: 'Fecha de Inclusión',
      RenewalDate: 'Fecha de Renovación',
      Save: 'Guardar',
      ConfirmDeleteDocument: '¿Desea eliminar el documento?',

      Filters: 'Filtros',
      AppliedFilters: 'Filtros Aplicados',
      DocumentFilters: 'Filtros de Documentos',
      Apply: 'Aplicar',
      Clear: 'Limpiar',
    },
  },
}
