/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'

import { Button, Card, Col, Form, Input, InputNumber, Row, Space } from 'antd'
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { translate } from '~/utils/locale'
import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'
import ClientModalMaintenance from './Modal/Client'
import ContributorsMaintenanceTable from './Modal/Contributor/Table'

// import { Container } from './styles';

const CreateMaintenanceComponent: React.FC = () => {
  const { createMaintenance, clientData, contributorData, activityData } =
    useMaintenance()

  const [form] = Form.useForm()

  const [value, setValue] = useState<number | null>(null)

  const handleChange = (val: any) => {
    if (val !== null && val >= 0 && val <= 99) {
      setValue(val)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
    const isNumberKey = /^[0-9]$/.test(event.key)

    if (!isNumberKey && !allowedKeys.includes(event.key)) {
      event.preventDefault()
    }

    if (isNumberKey && value !== null && value.toString().length >= 2) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    if (activityData?.id) {
      form.setFieldsValue({ activity: activityData?.name })
    }

    if (clientData?.id) {
      form.setFieldsValue({ customer: clientData?.name })
    }

    if (contributorData?.id) {
      form.setFieldsValue({
        responsible_name: contributorData?.name,
        phone: contributorData?.phone,
      })
    }
  }, [form, clientData, contributorData, activityData])

  function onCreateMaintenance(valueForm: any) {
    try {
      const formatData = {
        name: valueForm.name,
        customer: {
          name: valueForm.customer,
          id: clientData?.id,
          address: clientData?.address,
        },
        description: valueForm.description,
        responsible: {
          name: valueForm.responsible_name,
          phone: valueForm.phone,
          id: contributorData?.id,
        },
        future_days: valueForm.future_days,
      }

      createMaintenance(formatData)

      form.resetFields()
    } catch (err) {}
  }

  return (
    <Card
      title={
        <Space size="large">
          <Link to="/manutencao">
            <ArrowLeftOutlined />
          </Link>
          <b>{translate('MaintenanceCreate.NewMaintenance')}</b>
        </Space>
      }
      extra={
        <Space size="middle">
          <Button
            type="primary"
            icon={<SaveOutlined />}
            htmlType="submit"
            form="maintenance"
            id="btn_create_maintenance"
          >
            {translate('MaintenanceCreate.Save')}
          </Button>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <Form
        onFinish={onCreateMaintenance}
        form={form}
        layout="vertical"
        id="maintenance"
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label="Nome do Plano"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Informe o nome do plano',
                },
              ]}
            >
              <Input placeholder="Informe o nome do plano" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>{translate('MaintenanceCreate.Client')}</p>

                  {clientData?.id ? <ClientModalMaintenance /> : ''}
                </Space>
              }
              name="customer"
              rules={[
                {
                  required: true,
                  message: 'Informe o cliente',
                },
              ]}
            >
              {clientData?.id ? <Input /> : <ClientModalMaintenance />}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>{translate('MaintenanceCreate.Employee')}</p>
                  {contributorData?.id ? <ContributorsMaintenanceTable /> : ''}
                </Space>
              }
              name="responsible_name"
              rules={[
                {
                  required: true,
                  message: 'Informe o colaborador',
                },
              ]}
            >
              {contributorData?.id ? (
                <Input />
              ) : (
                <ContributorsMaintenanceTable />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('MaintenanceCreate.ResponsiblePhone')}
              name="phone"
            >
              <Input placeholder="(99) 99999-9999" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              label="Dias futuros para criar as atividades"
              name="future_days"
            >
              <InputNumber
                onKeyPress={handleKeyPress}
                style={{ width: '100%' }}
                placeholder="Informe os dias"
                value={value}
                onChange={handleChange}
                min={0}
                max={99}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label={translate('MaintenanceCreate.Description')}
              name="description"
            >
              <Input.TextArea
                placeholder={translate(
                  'MaintenanceCreate.DescriptionPlaceholder',
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default CreateMaintenanceComponent
