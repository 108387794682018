/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Button,
  DatePicker,
  Form,
  Modal,
  Select,
  Space,
  Steps,
  Table,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'

import Text from 'antd/lib/typography/Text'

import React, { useEffect, useState } from 'react'

import { translate } from '~/utils/locale'

import { useActivity } from '~/hooks/Maintenance/Activity/useActivity'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { Link } from 'react-router-dom'
import ActivityFilterModal from '../../../Activity/Filter'
import { Dayjs } from 'dayjs'
import { useContributorsTask } from '~/hooks/Tasks/Create/useContributorTask'

interface ActivityProps {
  _id: string
  title: string
  createdAt: string
  component?: string
  activity?: string
  competence?: string
  periodicity: {
    type: string
    interval: number
    date: Date
  }
  data_font: string
}

const AddActivityModal: React.FC = () => {
  const { activity, isFetching, activityTotal } = useActivity()
  const { addActivity } = useShowMaintenance()
  const [activityId, setActivityId] = useState<React.Key[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { data } = useContributorsTask()

  const [current, setCurrent] = useState(0)
  const [date, setDate] = useState<Dayjs | null>(null)
  const [responsible, setResponsible] = useState<string>('')

  const next = () => setCurrent((prev) => prev + 1)
  const prev = () => setCurrent((prev) => prev - 1)

  const columns: ColumnsType<ActivityProps> = [
    {
      title: translate('MaintenanceSystemTab.Title'),
      key: 'title',
      dataIndex: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('SystemTable.nameColumn'),
      key: 'system',
      dataIndex: 'system',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceActivity.SelectComponents'),
      key: 'component',
      dataIndex: 'component',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceActivity.Activities'),
      key: 'activity',
      dataIndex: 'activity',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceSystemTab.Competence'),
      dataIndex: 'competence',
      key: 'competence',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceSystemTab.Periodicity'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: ActivityProps) => (
        <>
          {record.periodicity.type === 'day' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Day')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Days')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.periodicity.type === 'sem' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Week')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Weeks')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.periodicity.type === 'men' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Months')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.MonthsPlural')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.periodicity.type === 'anu' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Year')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Years')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}
        </>
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys: activityId, // Mantém as seleções sincronizadas
    onChange: (selectedRowKeys: React.Key[]) => {
      setActivityId(selectedRowKeys)
    },
    getCheckboxProps: (record: ActivityProps) => ({
      disabled: record.title === 'Disabled User', // Exemplo de desativação
      name: record.title,
    }),
  }

  const showModal = () => {
    setIsModalVisible(true)
    setActivityId([])
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    if (!isModalVisible) {
      setActivityId([])
    }
  }, [isModalVisible])

  const steps = [
    {
      title: 'Data de Início da Atividade',
      content: (
        <>
          <Form.Item
            label="Selecione a data de início da atividade"
            name="date"
          >
            <DatePicker
              style={{ width: '100%' }}
              value={date}
              format="DD/MM/YYYY"
              onChange={(e: Dayjs | null) => setDate(e)}
            />
          </Form.Item>

          <Form.Item label="Executor/Fornecedor" name="responsible">
            <Select
              placeholder="Executor/Fornecedor da Atividade"
              onChange={(value) => setResponsible(value)}
              style={{ width: '100%' }}
            >
              {data?.map((c) => (
                <Select.Option value={c.contact.name} key={c.id}>
                  {c.contact.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Escolher atividade',
      content: (
        <Table
          rowKey={(record) => record?._id}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: activityTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 5,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          }}
          columns={columns}
          size="middle"
          dataSource={activity}
          scroll={{ x: 1000 }}
        />
      ),
    },
  ]

  function AddActivity() {
    const equipmentsId = activityId.map((e) => {
      return { id: e, initialDate: date, responsible }
    })

    const data = {
      activities: equipmentsId,
    }

    try {
      addActivity(data)
      setIsModalVisible(false)
      setActivityId([])
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  return (
    <>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={showModal}
        style={{ width: '100%' }}
        type="primary"
      >
        {translate('MaintenanceSystemTab.AddActivity')}
      </Button>

      <Modal
        title={translate('MaintenanceSystemTab.AddActivity')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width={current === 1 ? '1100px' : '600px'}
        footer={[
          <Space key="FooterProps">
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()} disabled={!date}>
                Próximo
              </Button>
            )}
            {current > 0 && <Button onClick={() => prev()}>Voltar</Button>}

            {current === 1 ? (
              <>
                <ActivityFilterModal />
                <Link to="/atividades/novo" target="_blank">
                  <Button type="primary" icon={<PlusOutlined />}>
                    {translate('MaintenanceSystemTab.NewActivity')}
                  </Button>
                </Link>
              </>
            ) : (
              ''
            )}

            {activityId.length !== 0 && current === 1 ? (
              <Button type="primary" onClick={AddActivity}>
                {translate('MaintenanceSystemTab.AddActivity')}
              </Button>
            ) : (
              ''
            )}
          </Space>,
        ]}
      >
        <Steps current={current}>
          {steps.map((item, index) => (
            <Steps.Step key={index} title={item.title} />
          ))}
        </Steps>
        <Form layout="vertical" style={{ marginTop: 24 }}>
          {steps[current].content}
        </Form>
      </Modal>
    </>
  )
}

export default AddActivityModal
