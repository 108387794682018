/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  FileUnknownOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import {
  Button,
  Modal,
  Space,
  Tooltip,
  Table,
  Popconfirm,
  Avatar,
  notification, // Manter importação do notification
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useClientsListTask } from '~/hooks/Tasks/Create/clientsListTask'
import { translate } from '~/utils/locale'

import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'
import { stringToHslColor } from '~/utils/stringToHslColor'
import { createLetterAvatar } from '~/utils/letter'
import ClientsFilterModalMain from './ClientFilterModal'

interface ClientsProps {
  id: string
  contact?: {
    name: string
    email: string
    id: string
  }
  parent_cnpj?: string
}

interface ClientAddress {
  zip_code: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  location: { x: number; y: number }
}

interface ClientModal {
  type?: string
}

const ClientModalMaintenance: React.FC<ClientModal> = ({ type }) => {
  const {
    clients,
    clientsTotal,
    setNumberPage,
    setPageSize,
    isFetching,
    refetch,
  } = useClientsListTask()
  const { clientData, setClientData, contactData, setContactData } =
    useMaintenance()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [showButton, setShowButton] = useState(false)

  const showAddressNotification = () => {
    notification.warning({
      message: 'Endereço não encontrado',
      description:
        'Para criar um plano de manutenção, é necessário que o cliente tenha um endereço cadastrado.',
      duration: 5,
      placement: 'topRight',
    })
  }

  const linkToAddress = () => {
    window.open(
      `/clientes/visualizar/${contactData?.id}/contato/${contactData?.contact}/endereço`,
    )

    setShowButton(false)
  }

  async function handleClientTask(
    id: string,
    name: string,
    contact: string,
    address: ClientAddress,
  ) {
    refetch()
    if (address === undefined) {
      setContactData({ id, contact })
      setShowButton(true)
      showAddressNotification()
    } else {
      await setClientData({
        id,
        name,
        address,
      })

      setIsModalVisible(false)
    }
  }

  const columns: ColumnsType<ClientsProps> = [
    {
      title: translate('ClientsTable.columnActions'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Popconfirm
          title={translate('TasksCreateModalClient.addClientPopconfirm')}
          onConfirm={() =>
            handleClientTask(
              record.id,
              record.contact.name,
              record.contact.id,
              record.contact?.contactAddresses[0]?.address,
            )
          }
        >
          <Tooltip title={translate('TasksCreateModalClient.addClientTooltip')}>
            <Button size="small" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
    {
      title: '',
      align: 'center',
      key: 'avatar',
      render: (record) => (
        <Avatar
          style={{
            background: stringToHslColor(`${record && record.contact.name}`),
          }}
        >
          {createLetterAvatar(`${record && record.contact.name}`)}
        </Avatar>
      ),
    },
    {
      title: translate('ClientsTable.columnName'),
      align: 'left',
      dataIndex: ['contact', 'name'],
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Tooltip title={translate('TasksCreateModalClient.viewClientTooltip')}>
          <Link
            target="_blank"
            to={`/clientes/visualizar/${record.id}/contato/${record.contact?.id}/`}
          >
            {name}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: translate('ClientsTable.columnResponsible'),
      dataIndex: ['collaborator', 'contact', 'name'],
      align: 'center',
      key: 'contact',
      render: (contact: string) => (
        <>
          {contact || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsTable.columnEmail'),
      align: 'center',
      key: 'email',
      dataIndex: ['contact', 'email'],
    },
  ]

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {!clientData?.id ? (
        <Button
          icon={<PlusCircleOutlined />}
          onClick={showModal}
          style={{ width: '100%' }}
          size={type === 'edit' ? 'small' : 'middle'}
        >
          {translate('MaintenanceCreate.AddClient')}
        </Button>
      ) : (
        <Button size="small" icon={<ReloadOutlined />} onClick={showModal}>
          {translate('MaintenanceCreate.EditClient')}
        </Button>
      )}

      <Modal
        title={translate('MaintenanceCreate.AddClient')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,
          maxHeight: 'calc(100vh - 100px)',
        }}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalClient.closeButton')}
            </Button>

            <ClientsFilterModalMain />

            <Link target="_blank" to="/clientes/novo">
              <Button type="primary" icon={<PlusCircleOutlined />}>
                {translate('MaintenanceCreate.AddClientModal')}
              </Button>
            </Link>

            {showButton && (
              <Button type="link" onClick={linkToAddress}>
                Cadastrar Endereço
              </Button>
            )}
          </Space>,
        ]}
      >
        <Table
          rowKey={(record) => record.id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: clientsTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 5,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
            onChange: (page, pageSize) => {
              setPageSize(pageSize!)
              setNumberPage(page)
            },
          }}
          columns={columns}
          size="small"
          dataSource={clients}
          scroll={{ x: 1000 }}
        />
      </Modal>
    </>
  )
}

export default ClientModalMaintenance
