export default {
  pt: {
    MaintenanceActivity: {
      Activities: 'Atividades',
      TotalOf: 'total de',
      Activity: 'atividade',
      ActivityT: 'atividades',
      NewActivity: 'Nova Atividade',
      Title: 'Título',
      TitleP: 'Informe o título da atividade',
      SystemName: 'Nome do Sistema',
      ComponentsElements: 'Componente/Elemento',
      Responsibles: 'Equipe/Competência',
      Periodicity: 'Periodicidade',
      ConfirmDeleteActivity: 'Deseja mesmo deletar a atividade?',
      Filters: 'Filtros',
      FiltersTitle: 'Filtros de Atividades',
      AppliedFilter: 'Filtro Aplicado',
      ActivityTitle: 'Título da Atividade',
      EnterActivityTitle: 'Informe o título da atividade',
      SelectSystem: 'Nome do Sistema',
      SelectSystems: 'Selecione os sistemas',
      SelectComponents: 'Componentes',
      InitialDate: 'Data de início',
      SelectComponentsElements: 'Selecione os componentes',
      SelectResponsibles: 'Selecione os responsáveis',
      SearchActivity: 'Pesquisar atividade',
      Apply: 'Aplicar',
      Clear: 'Limpar',
    },
  },
  en: {
    MaintenanceActivity: {
      Activities: 'Activities',
      TotalOf: 'total of',
      Activity: 'activity',
      ActivityT: 'activities',
      NewActivity: 'New Activity',
      Title: 'Title',
      TitleP: 'Inform the activity title',
      SystemName: 'System Name',
      ComponentsElements: 'Component/Element',
      Responsibles: 'Team/Competence',
      Periodicity: 'Periodicity',
      ConfirmDeleteActivity: 'Do you really want to delete the activity?',
      Filters: 'Filters',
      FiltersTitle: 'Activities Filters',
      AppliedFilter: 'Applied Filter',
      ActivityTitle: 'Activity Title',
      EnterActivityTitle: 'Enter the activity title',
      SelectSystem: 'System Name',
      SelectSystems: 'Select the systems',
      SelectComponents: 'Components/Elements',
      SelectComponentsElements: 'Select the components',
      SelectResponsibles: 'Select the responsibles',
      InitialDate: 'Start Date',
      SearchActivity: 'Search activity',
      Apply: 'Apply',
      Clear: 'Clear',
    },
  },
  es: {
    MaintenanceActivity: {
      Activities: 'Actividades',
      TotalOf: 'total de',
      Activity: 'actividad',
      ActivityT: 'actividades',
      NewActivity: 'Nueva Actividad',
      Title: 'Título',
      TitleP: 'Introduce el título de la actividad',
      SystemName: 'Nombre del Sistema',
      ComponentsElements: 'Componente/Elemento',
      Responsibles: 'Equipo/Competencia',
      Periodicity: 'Periodicidad',
      ConfirmDeleteActivity: '¿Desea realmente eliminar la actividad?',
      Filters: 'Filtros',
      FiltersTitle: 'Filtros de Actividad',
      AppliedFilter: 'Filtro Aplicado',
      ActivityTitle: 'Título de la Actividad',
      EnterActivityTitle: 'Informe el título de la actividad',
      SelectSystem: 'Nombre del Sistema',
      SelectSystems: 'Seleccione los sistemas',
      SelectComponents: 'Componentes/Elementos',
      SelectComponentsElements: 'Seleccione los componentes',
      SelectResponsibles: 'Seleccione los responsables',
      SearchActivity: 'Pesquisar actividad',
      InitialDate: 'Fecha de inicio',
      Apply: 'Aplicar',
      Clear: 'Limpiar',
    },
  },
}
