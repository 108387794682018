/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import {
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Button,
  Tag,
  Typography,
} from 'antd'
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'

import { translate } from '~/utils/locale'
import { useSystem } from '~/hooks/Maintenance/System/useSystem'
import UpdateSystem from '../Update'

// import { Container } from './styles';

interface SystemProps {
  _id: string
  name: string
  createdAt: string
  components: [{ name: string; _id: string }]
}

interface ISystemTable {
  data: SystemProps[] | undefined
}

const SystemTable: React.FC<ISystemTable> = ({ data }) => {
  const { deleteSystem, isLoading } = useSystem()

  const { Text } = Typography

  const columns: ColumnsType<SystemProps> = [
    {
      title: 'ID Sistema',
      align: 'left',
      dataIndex: '_id',
      key: 'id',
      // defaultSortOrder: 'ascend',
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // sorter: (a, b) => a.contact.name.localeCompare(b.contact.name),
      render: (_id: string) => (
        <Text copyable={{ text: _id }}>{_id.substring(0, 8)}</Text>
      ),
    },
    {
      title: translate('SystemTable.nameColumn'),
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('SystemTable.componentColumn'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: SystemProps) => (
        <>
          {record?.components?.length === 1 ? (
            <Typography.Text>
              {record?.components?.[0]?.name?.toUpperCase()}
            </Typography.Text>
          ) : (
            <>
              {record?.components?.map((c) => (
                <Tag
                  color={c.name.length > 5 ? 'geekblue' : 'green'}
                  key={c._id}
                >
                  {c?.name?.toUpperCase()}
                </Tag>
              ))}
            </>
          )}
        </>
      ),
    },
    {
      title: translate('TasksRegistrationPauseTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <UpdateSystem data={record} />
          <Popconfirm
            title={translate('SystemTable.deleteConfirmText')}
            onConfirm={() => deleteSystem(record._id)}
          >
            <Tooltip
              title={translate('TasksRegistrationPauseTable.deleteTooltip')}
            >
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        rowKey={(record) => record._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={data}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default SystemTable
