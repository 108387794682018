/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
  RetweetOutlined,
} from '@ant-design/icons'
import { Button, Modal, Space, Tooltip, Table, Popconfirm } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import React, { useState } from 'react'

import { translate } from '~/utils/locale'

import { useSystem } from '~/hooks/Maintenance/System/useSystem'
import { useActivity } from '~/hooks/Maintenance/Activity/useActivity'
import SystemsFilterModal from '../../../Systems/Filter'
import { Link } from 'react-router-dom'

interface SystemProps {
  id: string
  name: string
  components: { name: string; _id: string }
}

interface System {
  type: string
}

const SystemModalMaintenance: React.FC<System> = ({ type }) => {
  const { system, systemTotal, isFetching, filterOptions, refetch } =
    useSystem()
  const { systemData, setSystemData } = useActivity()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const cId = JSON.parse(localStorage.getItem('@Gstor:systemFilterOptions')!)

  const flattenData = system?.flatMap((system) =>
    system.components.map((component) => ({
      id: system._id,
      name: system.name,
      components: component,
    })),
  )

  const filteredData = filterOptions.components
    ? flattenData?.filter((item) => item.components._id === cId?.components[0])
    : flattenData

  async function handleClientTask(id: string, name: string, component: string) {
    await setSystemData({
      id,
      name,
      component,
    })
    setIsModalVisible(false)
  }

  const columns: ColumnsType<SystemProps> = [
    {
      title: translate('ClientsTable.columnActions'),
      key: 'remove',
      render: (record) => (
        <Popconfirm
          title={translate('MaintenanceCreate.ConfirmSystem')}
          onConfirm={() =>
            handleClientTask(record.id, record.name, record.components.name)
          }
        >
          <Tooltip title={translate('MaintenanceCreate.AddSystem')}>
            <Button size="small" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
    {
      title: translate('SystemTable.componentColumn'),
      key: 'component',
      dataIndex: ['components', 'name'],
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('SystemTable.nameColumn'),
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ]

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {!systemData?.id ? (
        <Button
          icon={<PlusCircleOutlined />}
          onClick={showModal}
          style={{ width: '100%' }}
          size={type === 'edit' ? 'small' : 'middle'}
        >
          {type === 'edit' ? (
            'Alterar Componente'
          ) : (
            <>{translate('MaintenanceCreate.AddSystem')}</>
          )}
        </Button>
      ) : (
        <Button size="small" icon={<ReloadOutlined />} onClick={showModal}>
          {translate('MaintenanceCreate.EditSystem')}
        </Button>
      )}

      <Modal
        title={
          <Space>
            {translate('MaintenanceCreate.AddSystem')}
            <Tooltip title="Atualizar listagem">
              <Button
                type="primary"
                ghost
                icon={<RetweetOutlined />}
                size="small"
                shape="circle"
                onClick={() => refetch()}
              />
            </Tooltip>
          </Space>
        }
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,
          maxHeight: 'calc(100vh - 100px)',
        }}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalClient.closeButton')}
            </Button>

            <SystemsFilterModal type="pattern" />

            <Link target="_blank" to="/manutencao/sistemas">
              <Button type="primary" icon={<PlusCircleOutlined />}>
                Criar componentes
              </Button>
            </Link>
          </Space>,
        ]}
      >
        <Table
          rowKey={(record) => `${record.id}-${record.components._id}`}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: systemTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 5,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          }}
          columns={columns}
          size="small"
          dataSource={filteredData}
          scroll={{ x: 1000 }}
        />
      </Modal>
    </>
  )
}

export default SystemModalMaintenance
