import { Button, Card, Col, List, Modal, Row, Tabs } from 'antd'
import React, { useState } from 'react'
import { FiCalendar, FiFile, FiTarget, FiUsers } from 'react-icons/fi'
import { translate } from '~/utils/locale'

import Text from 'antd/lib/typography/Text'
import { ProfileOutlined } from '@ant-design/icons'

import { Container } from './styles'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'

interface ActivityProps {
  _id: string
  title: string
  createdAt: string
  component?: string
  activity?: string
  competence?: string
  periodicity: {
    type: string
    interval: number
    date: Date
  }
  initialDate: Date
  responsible: string
  data_font: string
  tasks: [
    {
      taskId: string
      created: Date
    },
  ]
}

interface Activity {
  activity: ActivityProps
}

const ViewActivity: React.FC<Activity> = ({ activity }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button
        ghost
        type="primary"
        shape="circle"
        icon={<ProfileOutlined />}
        onClick={showModal}
      />
      <Modal
        title={`${translate('MaintenanceSystemTab.ViewActivity')} - ${
          activity.title
        }`}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksCreateModalClient.closeButton')}
          </Button>,
        ]}
      >
        <Tabs>
          <Tabs.TabPane
            key="details"
            tab={translate('MaintenanceSystemTab.Details')}
          >
            <Container>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiFile />
                      <Text strong>
                        {translate('MaintenanceSystemTab.ActivityTitle')}
                      </Text>
                    </div>
                    {activity.title ? (
                      <Text style={{ fontSize: 16 }}>{activity.title}</Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiTarget />
                      <Text strong>
                        {translate('MaintenanceSystemTab.DataSource')}
                      </Text>
                    </div>
                    {activity.data_font ? (
                      <Text style={{ fontSize: 16 }}>{activity.data_font}</Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiUsers />
                      <Text strong>
                        {translate('MaintenanceSystemTab.Competence')}
                      </Text>
                    </div>
                    {activity.competence ? (
                      <Text style={{ fontSize: 16 }}>
                        {activity.competence}
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiUsers />
                      <Text strong>
                        {translate('MaintenanceSystemTab.Responsibles')}
                      </Text>
                    </div>
                    {activity.responsible ? (
                      <Text style={{ fontSize: 16 }}>
                        {activity.responsible}
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiCalendar />
                      <Text strong>
                        {translate('MaintenanceSystemTab.Periodicity')}
                      </Text>
                    </div>
                    {activity.periodicity ? (
                      <>
                        {activity.periodicity.type === 'day' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {activity.periodicity.interval}{' '}
                            {translate('MaintenanceSystemTab.Days')}
                          </Text>
                        ) : (
                          ''
                        )}

                        {activity.periodicity.type === 'sem' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {activity.periodicity.interval}{' '}
                            {translate('MaintenanceSystemTab.Weeks')}
                          </Text>
                        ) : (
                          ''
                        )}

                        {activity.periodicity.type === 'men' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {activity.periodicity.interval}{' '}
                            {translate('MaintenanceSystemTab.MonthsPlural')}
                          </Text>
                        ) : (
                          ''
                        )}

                        {activity.periodicity.type === 'anu' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {activity.periodicity.interval}{' '}
                            {translate('MaintenanceSystemTab.Years')}
                          </Text>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <Text>{translate('TasksTabsDetails.noToText')}</Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiCalendar />
                      <Text strong>
                        {translate('MaintenanceActivity.InitialDate')}
                      </Text>
                    </div>

                    {activity.initialDate
                      ? format(new Date(activity?.initialDate), 'dd/MM/yyyy')
                      : 'Nenhuma data informada'}
                  </div>
                </Col>
              </Row>
            </Container>
          </Tabs.TabPane>

          <Tabs.TabPane key="components" tab="Componentes e Atividades">
            <Card
              style={{ marginTop: 16 }}
              title={`Atividade do componente - ${activity.component}`}
            >
              {activity.activity}
            </Card>
          </Tabs.TabPane>

          <Tabs.TabPane key="tasks" tab="Tarefas">
            <List
              itemLayout="horizontal"
              dataSource={activity.tasks}
              size="small"
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <Link
                        to={`/tarefas/visualizar/${item.taskId}`}
                        target="_blank"
                      >
                        Tarefa - {item.taskId.substring(0, 8)}
                      </Link>
                    }
                    description={`Criada em: ${format(
                      new Date(item.created),
                      'dd/MM/yyyy',
                    )}`}
                  />
                </List.Item>
              )}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  )
}

export default ViewActivity
