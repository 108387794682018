export default {
  pt: {
    useActivity: {
      getErrorMessage: 'Erro ao visualizar as atividades.',
      createSuccessMessage: 'Atividade criada com sucesso!',
      createErrorMessage: 'Erro ao criar uma atividade.',
      updateSuccessMessage: 'Atividade atualizada com sucesso!',
      updateErrorMessage: 'Erro ao atualizar a atividade.',
      deleteSuccessMessage: 'Atividade deletada com sucesso!',
      deleteErrorMessage: 'Erro ao deletar a atividade.',
    },
  },
  en: {
    useActivity: {
      getErrorMessage: 'Error viewing activities.',
      createSuccessMessage: 'Activity created successfully!',
      createErrorMessage: 'Error creating the activity.',
      updateSuccessMessage: 'Activity updated successfully!',
      updateErrorMessage: 'Error updating activity.',
      deleteSuccessMessage: 'Activity deleted successfully!',
      deleteErrorMessage: 'Error deleting activity.',
    },
  },
  es: {
    useActivity: {
      getErrorMessage: 'Error al ver los .',
      createSuccessMessage: '¡Actividad creada con éxito!',
      createErrorMessage: 'Error al crear actividad.',
      updateSuccessMessage: '¡Actividad actualizada con éxito!',
      updateErrorMessage: 'Error al actualizar el actividad.',
      deleteSuccessMessage: '¡Actividad eliminada con éxito!',
      deleteErrorMessage: 'Error al eliminar el actividad.',
    },
  },
}
