export default {
  pt: {
    SystemCreate: {
      addButton: 'Adicionar Novo Sistema',
      modalTitle: 'Novo Sistema',
      keepRegisterText: 'Continuar cadastrando Sistemas?',
      closeButton: 'Fechar',
      saveButton: 'Salvar',
      nameLabel: 'Nome',
      nameRule: 'Por favor, informe o nome do sistema.',
      namePlaceholder: 'Informe o nome do sistema',
      componentLabel: 'Componentes',
      componentRule: 'Por favor informe o adicione do sistema.',
      componentPlaceholder: 'Informe o componente.',
      activityLabel: 'Atividades',
      activityPlaceholder: 'Informe a atividade',
      addActivityButton: 'Adicionar atividade',
      removeActivityButton: 'Remover atividade',
      periodicityText: 'Periodicidade',
      typeText: 'Frequência',
      intervalText: 'Intervalo',
      dateText: 'Data de Início',
      typeTextPlaceholder: 'Selecione o tipo',
      intervalTextPlaceholder: 'Informe o intervalo',
      dateTextPlaceholder: 'Informe a data de início',
      dayText: 'Diário',
      weekText: 'Semanal',
      monthText: 'Mensal',
      yearText: 'Anual',
    },
  },
  en: {
    SystemCreate: {
      addButton: 'Add System',
      modalTitle: 'New System',
      keepRegisterText: 'Continue registering system?',
      closeButton: 'Close',
      saveButton: 'Save',
      nameLabel: 'Name',
      nameRule: 'Please enter the name of system.',
      namePlaceholder: 'Inform the title',
      componentLabel: 'Components',
      componentRule: 'Please enter the system component.',
      componentPlaceholder: 'Inform the system component.',
      activityLabel: 'Activity',
      activityPlaceholder: 'Enter the activity',
      addActivityButton: 'Add Activity',
      removeActivityButton: 'Delete Activity',
      periodicityText: 'Periodicity',
      typeText: 'Frequency',
      intervalText: 'Interval',
      dateText: 'Start date',
      typeTextPlaceholder: 'Select the type',
      intervalTextPlaceholder: 'Inform the interval',
      dateTextPlaceholder: 'Inform the start date',
      dayText: 'Days',
      weekText: 'Weekly',
      monthText: 'Monthly',
      yearText: 'Annual',
    },
  },
  es: {
    SystemCreate: {
      addButton: 'Añadir Sistema',
      modalTitle: 'Nuevo Sistema',
      keepRegisterText: 'Continuar registrando sistema?',
      closeButton: 'Cerrar',
      saveButton: 'Guardar',
      nameLabel: 'Nombre del Sistema',
      nameRule: 'Por favor, agregue el nombre del sistema.',
      namePlaceholder: 'Ingrese el nombre del sistema',
      componentLabel: 'Componentes',
      componentRule: 'Por favor, agregue el componente del sistema.',
      componentPlaceholder: 'Ingrese el componente del sistema',
      activityLabel: 'Actividad',
      activityPlaceholder: 'Ingrese la actividad',
      addActivityButton: 'Agregar Actividad',
      removeActivityButton: 'Eliminar Actividad',
      periodicityText: 'Periodicidad',
      typeText: 'Frequencia',
      intervalText: 'Intervalo',
      dateText: 'Fecha de inicio',
      typeTextPlaceholder: 'Seleccionar tipo',
      intervalTextPlaceholder: 'Ingrese el intervalo',
      dateTextPlaceholder: 'Introduzca la fecha de inicio',
      dayText: 'Días',
      weekText: 'Semanalmente',
      monthText: 'Mensual',
      yearText: 'Anual',
    },
  },
}
