/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { ColumnsType } from 'antd/lib/table'
import { Popconfirm, Space, Table, Tooltip, Button, Tag } from 'antd'
import {
  DeleteFilled,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'

import Text from 'antd/lib/typography/Text'
import { Link } from 'react-router-dom'

import { useListGroup } from '~/hooks/Groups/useListGroup'
import { translate } from '~/utils/locale'

interface UserGroup {
  id: string
  user: {
    id: string
    contact: {
      id: string
      name: string
    }
  }
}

interface Permissions {
  id: string
  create: boolean
  delete: boolean
  edit: boolean
  view: boolean
  module: {
    id: string
    name: string
  }
}

interface GroupProps {
  name: string
  id: string
  type: string
  userGroups: UserGroup[]
  permissions: Permissions[]
}

const GroupsTable: React.FC = () => {
  const { group, deleteGroup, isLoading } = useListGroup()

  console.log('Grupos', group)

  const columns: ColumnsType<GroupProps> = [
    {
      title: translate('GroupsTable.nameColumn'),
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link to={`/permissoes/visualizar/${record.id}`}>{name}</Link>
      ),
    },
    {
      title: translate('GroupsTable.groupTypeColumn'),
      key: 'group_type',
      render: (record) => (
        <>
          {record.type === 'admin' ? (
            <Tag color="blue">{translate('GroupsTable.groupTypeAdmin')}</Tag>
          ) : (
            ''
          )}
          {record.type === 'custom' ? (
            <Tag color="green">{translate('GroupsTable.groupTypeCustom')}</Tag>
          ) : (
            ''
          )}
          {record.type === 'user' ? (
            <Tag color="volcano">{translate('GroupsTable.groupTypeUser')}</Tag>
          ) : (
            ''
          )}
          {record.type === 'manager' ? (
            <Tag color="purple">
              {translate('GroupsTable.groupTypeManager')}
            </Tag>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: translate('GroupsTable.groupMembersColumn'),
      key: 'group_members',
      render: (record) => (
        <>
          {record.userGroups.length === 1 ? (
            <Text>
              {record.userGroups.length} {translate('GroupsTable.userText')}
            </Text>
          ) : (
            <Text>
              {record.userGroups.length} {translate('GroupsTable.usersText')}
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('GroupsTable.groupPermissionsColumn'),
      key: 'group_permissions',
      render: (record) => (
        <>
          {record.permissions.length === 1 ? (
            <Text>
              {record.permissions.length}{' '}
              {translate('GroupsTable.permissionText')}
            </Text>
          ) : (
            <Text>
              {record.permissions.length}{' '}
              {translate('GroupsTable.permissionsText')}
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('GroupsTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('GroupsTable.viewTooltip')}>
            <Link to={`/permissoes/visualizar/${record.id}`}>
              <Button
                type="primary"
                ghost
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          {record.type === 'admin' ||
          record.type === 'manager' ||
          record.type === 'user' ? (
            ''
          ) : (
            <Popconfirm
              title={translate('GroupsTable.deletePopconfim')}
              onConfirm={() => deleteGroup(record.id)}
            >
              <Tooltip title={translate('GroupsTable.deleteTooltip')}>
                <Button
                  type="primary"
                  ghost
                  danger
                  shape="circle"
                  icon={<DeleteFilled />}
                />
              </Tooltip>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        columns={columns}
        dataSource={group}
        scroll={{ x: 1000 }}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default GroupsTable
