/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import { Popconfirm, Space, Table, Button, Tooltip, Collapse } from 'antd'
import {
  DeleteFilled,
  FileUnknownOutlined,
  LoadingOutlined,
} from '@ant-design/icons'

import Text from 'antd/lib/typography/Text'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import AboutTypeTasks from '../About'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface MandatoryProps {
  is_active: boolean
  name: string
  type: string
  value: boolean
}

interface TaskTypeProps {
  description: string
  is_enabled: boolean
  runtime: string
  tolerance_time: string
  _id: string
  createdAt: string
  title: string
  amount_to_pay: number
  amount_to_receive: number
  mandatory: MandatoryProps[]
}

const TypeTasksTable: React.FC = () => {
  const { isLoading, taskType, deleteTaskType } = useTaskType()

  const { Panel } = Collapse

  const typeTasksFormatted = taskType?.map((task: TaskTypeProps) => {
    const dataFormat = {
      ...task,
      runtimeFormat: task?.runtime?.substring(0, 5),
      toleranceFormat: task?.tolerance_time?.substring(0, 5),
    }

    return dataFormat
  })
  const columns: ColumnsType<TaskTypeProps> = [
    {
      title: translate('TasksRegistrationTypeTasksTable.taskTypeColumn'),
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: translate('TasksRegistrationTypeTasksTable.toleranceColumn'),
      key: 'tolerance',
      dataIndex: 'toleranceFormat',
    },
    {
      title: translate('TasksRegistrationTypeTasksTable.runtimeColumn'),
      key: 'runtime',
      dataIndex: 'runtimeFormat',
    },
    {
      title: translate('TasksRegistrationTypeTasksTable.descriptionColumn'),
      key: 'description',
      dataIndex: 'description',
      render: (description: string, record) => (
        <>
          <Collapse bordered={false}>
            <Panel
              header="Ver descrição"
              key={record._id}
              style={{ padding: -10, border: 'none', borderRadius: 5 }}
            >
              {description || (
                <Text type="secondary">
                  <Space size="small">
                    <FileUnknownOutlined />
                    N/A
                  </Space>
                </Text>
              )}
            </Panel>
          </Collapse>
        </>
      ),
    },
    {
      title: translate('TasksRegistrationTypeTasksTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <AboutTypeTasks data={record} />
          <Popconfirm
            title={translate(
              'TasksRegistrationTypeTasksTable.deletePopconfirm',
            )}
            onConfirm={() => deleteTaskType(record._id)}
          >
            <Tooltip
              title={translate('TasksRegistrationTypeTasksTable.deleteTooltip')}
            >
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record._id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isLoading,
        size: 'large',
      }}
      size="small"
      columns={columns}
      dataSource={typeTasksFormatted}
      scroll={{ x: 1000 }}
      pagination={{
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
      }}
    />
  )
}

export default TypeTasksTable
