export default {
  pt: {
    SystemTable: {
      nameColumn: 'Nome do Sistema',
      componentColumn: 'Componentes',
      activityColumn: 'Atividades',
      deleteConfirmText: 'Deseja mesmo deletar o sistema?',
      activityText: 'atividade',
      activitiesText: 'atividades',
    },
  },
  en: {
    SystemTable: {
      nameColumn: 'Name of System',
      componentColumn: 'Components',
      activityColumn: 'Activities',
      deleteConfirmText: 'Do you really want to delete the system?',
      activityText: 'activity',
      activitiesText: 'activities',
    },
  },
  es: {
    SystemTable: {
      nameColumn: 'Nombre del Sistema',
      componentColumn: 'Componentes',
      activityColumn: 'Actividades',
      deleteConfirmText: '¿Realmente quieres eliminar el sistema?',
      activityText: 'actividade',
      activitiesText: 'actividades',
    },
  },
}
