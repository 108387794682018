import React from 'react'
import ActivityBoard from '~/components/DataManipulation/Maintenance/Activity'
import { ActivityProvider } from '~/hooks/Maintenance/Activity/useActivity'
import { SystemProvider } from '~/hooks/Maintenance/System/useSystem'
import { ContributorsTaskProvider } from '~/hooks/Tasks/Create/useContributorTask'

// import { Container } from './styles';

const Activity: React.FC = () => {
  return (
    <ActivityProvider>
      <SystemProvider>
        <ContributorsTaskProvider>
          <ActivityBoard />
        </ContributorsTaskProvider>
      </SystemProvider>
    </ActivityProvider>
  )
}

export default Activity
