/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Tooltip,
  Select,
  Space,
  InputNumber,
} from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import { Container } from './styles'

import { translate } from '~/utils/locale'

import { useSystem } from '~/hooks/Maintenance/System/useSystem'
import { useActivity } from '~/hooks/Maintenance/Activity/useActivity'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'

const ActivityFilterModal: React.FC = () => {
  const { handleUpdateFilters, handleResetFilters, filterOptions, activity } =
    useActivity()

  const { system } = useSystem()

  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      title: filterOptions?.title,
      system: filterOptions?.system,
      competence: filterOptions?.competence,
      component: filterOptions?.component,
      activity: filterOptions?.activity,
      periodicityType: filterOptions?.periodicityType,
      periodicityInterval: filterOptions?.periodicityInterval,
    })
  }, [filterOptions, form])

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = (dataForm: any) => {
    handleUpdateFilters(dataForm)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        loading={!activity}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('TaskTypesFilter.cleanFilterTooltip')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_activity_maintenance"
      >
        {isFilter
          ? translate('MaintenanceActivity.AppliedFilter')
          : translate('MaintenanceActivity.Filters')}
      </Button>
      <Drawer
        forceRender
        title={translate('MaintenanceActivity.FiltersTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceActivity.ActivityTitle')}
                name="title"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceActivity.EnterActivityTitle',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceActivity.SelectSystem')}
                name="system"
              >
                <Select
                  placeholder={translate('MaintenanceActivity.SelectSystems')}
                >
                  {system?.map((s) => (
                    <Select.Option key={s._id} value={s.name}>
                      {s.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label={translate('MaintenanceCreate.Component')}
                name="component"
              >
                <Input.TextArea
                  placeholder={translate('SystemCreate.componentPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={24}>
              <Form.Item label="Atividade" name="activity">
                <Input.TextArea placeholder="Informe o nome da atividade" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('MaintenanceCreate.Type')}
                    <HelpTooltip title="Refere-se ao intervalo de repetição do evento ou ação." />
                  </Space>
                }
                name="periodicityType"
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.TypePlaceholder')}
                >
                  <Select.Option value="day">
                    {translate('MaintenanceCreate.Daily')}
                  </Select.Option>
                  <Select.Option value="sem">
                    {translate('MaintenanceCreate.Weekly')}
                  </Select.Option>
                  <Select.Option value="men">
                    {translate('MaintenanceCreate.Monthly')}
                  </Select.Option>
                  <Select.Option value="anu">
                    {translate('MaintenanceCreate.Yearly')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('MaintenanceCreate.Interval')}
                    <HelpTooltip title="Representa o número de ciclos de frequência antes de cada repetição" />
                  </Space>
                }
                name="periodicityInterval"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.EnterInterval')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <Space>
                    <p>Equipe/Competência</p>
                  </Space>
                }
                name="competence"
              >
                <Input placeholder="Informe a equipe/competência" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('MaintenanceActivity.Apply')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('MaintenanceActivity.Clear')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default ActivityFilterModal
