/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState, useCallback } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { useHistory } from 'react-router-dom'

interface ActivityProps {
  _id: string
  title: string
  createdAt: string
  component?: string
  system: string
  activity?: string
  competence?: string
  periodicity: {
    type: string
    interval: number
    date: Date
    weekend: string
  }
  data_font: string
}

interface DataProps {
  id?: string
  name: string
  component: string
}

interface FilterOptionsProps {
  title?: string
  system?: string
  component?: string
  activity?: string
  competence?: string
  periodicityType?: string
  periodicityInterval?: number
}

interface ActivityContextData {
  activity?: ActivityProps[]
  createActivity(valueForm: object | undefined): void
  updateActivity(valueForm: object | undefined, activityId: string): void
  deleteActivity(activityId: string): void
  systemData?: DataProps
  setSystemData(value: DataProps): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  activityTotal?: number
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
}

const ActivityContext = createContext<ActivityContextData>(
  {} as ActivityContextData,
)

export const ActivityProvider: React.FC = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:ActivityMainFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const history = useHistory()

  const [activityTotal, setActivityTotal] = useState<number>()

  const [systemData, setSystemData] = useState<DataProps>({} as DataProps)

  const {
    data: activity,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `atividades/gestao${selectedCompany}`,
      selectedCompany,
      activityTotal,
      filterOptions,
    ],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/activities`,
          {
            params: {
              title: filterOptions?.title,
              system: filterOptions?.system,
              component: filterOptions?.component,
              activity: filterOptions?.activity,
              periodicityType: filterOptions?.periodicityType,
              periodicityInterval: filterOptions?.periodicityInterval,
              competence: filterOptions?.competence,
            },
          },
        )

        const { data } = response

        setActivityTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(err.message, translate('useActivity.getErrorMessage'), locale)
      }
    },
  )

  async function createActivity(valueForm: object) {
    try {
      await api.post(`/company/${selectedCompany}/activity`, valueForm)

      refetch()
      message.success(translate('useActivity.createSuccessMessage'))

      history.push('/atividades')
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useActivity.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateActivity(valueForm: object, activityId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/activity/${activityId}`,
        valueForm,
      )

      message.success(translate('useActivity.updateSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useActivity.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteActivity(activityId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/activity/${activityId}`)

      refetch()
      message.success(translate('useActivity.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useActivity.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      title: newFilterOptions?.title,
      system: newFilterOptions?.system,
      competence: newFilterOptions?.competence,
      component: newFilterOptions?.component,
      activity: newFilterOptions?.activity,
      periodicityType: newFilterOptions?.periodicityType,
      periodicityInterval: newFilterOptions?.periodicityInterval,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:ActivityMainFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:ActivityMainFilterOptions')
    setFilterOptions({})
  }, [])

  return (
    <ActivityContext.Provider
      value={{
        activity,
        createActivity,
        updateActivity,
        deleteActivity,
        isLoading,
        refetch,
        isFetching,
        activityTotal,
        systemData,
        setSystemData,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
      }}
    >
      {children}
    </ActivityContext.Provider>
  )
}

export function useActivity(): ActivityContextData {
  const context = useContext(ActivityContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
