export default {
  pt: {
    MaintenanceBoard: {
      cardTitle: 'Planos de Manutenção e Conservação Patrimonial',
      totalText: 'Total de',
      maintenanceText: 'plano de manutenção',
      maintenancesText: 'planos de manutenções',
      newMaintenanceButton: 'Novo Plano de Manutenção',
    },
  },
  en: {
    MaintenanceBoard: {
      cardTitle: 'Property Maintenance and Conservation Plans',
      totalText: 'Total of',
      maintenanceText: 'maintenance plan',
      maintenancesText: 'maintenance plans',
      newMaintenanceButton: 'New Maintenance Plan',
    },
  },
  es: {
    MaintenanceBoard: {
      cardTitle: 'Planes de Mantenimiento y Conservación de Propiedades',
      totalText: 'Total de',
      maintenanceText: 'plan de mantenimiento',
      maintenancesText: 'plans de mantenimiento',
      newMaintenanceButton: 'Nuevo Plan Mantenimiento',
    },
  },
}
