/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Col, Row, Space } from 'antd'
import React from 'react'
import { FiFile, FiPhone, FiUser } from 'react-icons/fi'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'
import { translate } from '~/utils/locale'

import Text from 'antd/lib/typography/Text'

import { Container } from './styles'
import NumberFormat from 'react-number-format'
import { stringToHslColor } from '~/utils/stringToHslColor'
import { createLetterAvatar } from '~/utils/letter'

const DetailsMaintenanceTabs: React.FC = () => {
  const { isLoading, maintenance, setIsEdit } = useShowMaintenance()

  if (isLoading || !maintenance) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  return (
    <Card
      bordered={false}
      title={`${translate(
        'MaintenanceDetailsTab.MaintenanceDetails',
      )} - ${maintenance?.name}`}
      extra={
        <Space>
          <Button
            id="btn-edit-maintenance"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => setIsEdit(true)}
          >
            {translate('MaintenanceDetailsTab.Edit')}
          </Button>
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
    >
      <Container>
        <div>
          <Avatar
            shape="circle"
            size={165}
            style={{
              fontSize: 40,
              background: '#EAEAEA',
              color: stringToHslColor(`${maintenance.customer?.name}`),
            }}
          >
            {createLetterAvatar(`${maintenance.customer?.name}`)}
          </Avatar>
        </div>
        <div className="content-row">
          <Row>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiUser size={16} />
                  <Text style={{ fontSize: 16 }} strong>
                    {translate('MaintenanceDetailsTab.ClientCondominium')}
                  </Text>
                </div>
                {maintenance.customer?.name ? (
                  <Text style={{ fontSize: 16 }}>
                    {maintenance.customer.name}
                  </Text>
                ) : (
                  <Text style={{ fontSize: 16 }}>
                    {translate('TasksTabsDetails.noToText')}
                  </Text>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiUser size={16} />
                  <Text style={{ fontSize: 16 }} strong>
                    {translate('MaintenanceDetailsTab.ResponsibleEmployee')}
                  </Text>
                </div>
                {maintenance.responsible.name ? (
                  <Text style={{ fontSize: 16 }}>
                    {maintenance.responsible.name}
                  </Text>
                ) : (
                  <Text style={{ fontSize: 16 }}>
                    {translate('TasksTabsDetails.noToText')}
                  </Text>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div className="text">
                <div>
                  <FiPhone size={16} />
                  <Text style={{ fontSize: 16 }} strong>
                    {translate(
                      'MaintenanceDetailsTab.ResponsibleEmployeePhone',
                    )}
                  </Text>
                </div>
                {maintenance.responsible.phone ? (
                  <Text style={{ fontSize: 16 }}>
                    <NumberFormat
                      value={maintenance.responsible.phone}
                      displayType="text"
                      format="+## (##) #####-####"
                    />
                  </Text>
                ) : (
                  <Text style={{ fontSize: 16 }}>
                    {translate('TasksTabsDetails.noToText')}
                  </Text>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiFile size={16} />
                  <Text style={{ fontSize: 16 }} strong>
                    {translate('MaintenanceDetailsTab.Description')}
                  </Text>
                </div>
                {maintenance.description ? (
                  <Text style={{ fontSize: 16 }}>
                    {maintenance.description}
                  </Text>
                ) : (
                  <Text style={{ fontSize: 16 }}>
                    Nenhuma descrição foi informada.
                  </Text>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiFile size={16} />
                  <Text style={{ fontSize: 16 }} strong>
                    Dias futuros para criar as atividades
                  </Text>
                </div>
                {maintenance.future_days ? (
                  <Text style={{ fontSize: 16 }}>
                    {maintenance.future_days} dias
                  </Text>
                ) : (
                  <Text style={{ fontSize: 16 }}>
                    Nenhum dia foi informado.
                  </Text>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Card>
  )
}

export default DetailsMaintenanceTabs
