/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, Space, Table, Tag, Tooltip, Button } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import React from 'react'
import { Link } from 'react-router-dom'
import { useClientsContacts } from '~/hooks/Clients/useContactClients'
import { translate } from '~/utils/locale'

import UpdateContact from '../../ClientsTabs/ContactsTab/Update'

// interface ContactProps {
//   contact: {
//     name: string;
//     id: number;
//     email: string;
//     phone: string;
//   };
//   customer: {
//     id: string;
//     contact: {
//       id: string;
//       name: string;
//     };
//   };
//   office: string;
//   is_responsible: boolean;
//   id: string;
// }

const ClientsContactsTable: React.FC = () => {
  const {
    listContacts,
    isLoading,
    clientContactTotal,
    setNumberPage,
    setPageSize,
    deleteContact,
  } = useClientsContacts()

  const columns: ColumnsType<any> = [
    {
      title: translate('ClientsContactsTable.nameColumn'),
      align: 'left',
      key: 'name',
      dataIndex: ['contact', 'name'],
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('ClientsContactsTable.emailColumn'),
      align: 'left',
      key: 'email',
      dataIndex: ['contact', 'email'],
    },
    {
      title: translate('ClientsContactsTable.officeColumn'),
      align: 'left',
      key: 'office',
      dataIndex: 'office',
    },
    {
      title: translate('ClientsContactsTable.clientColumn'),
      align: 'left',
      key: 'client',
      dataIndex: ['customer', 'contact', 'name'],
      render: (name: string, record) => (
        <Tooltip title={translate('ClientsContactsTable.clientTooltip')}>
          <Link
            to={`clientes/visualizar/${record?.customer?.id}/contato/${record?.customer?.contact?.id}`}
          >
            {name}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: translate('ClientsContactsTable.responsibleColumn'),
      align: 'left',
      key: 'is_responsible',
      dataIndex: 'is_responsible',
      render: (is_responsible: boolean) => (
        <>
          {is_responsible ? (
            <Tag color="green">
              {translate('ClientsContactsTable.responsibleYes')}
            </Tag>
          ) : (
            <Tag color="red">
              {translate('ClientsContactsTable.responsibleNo')}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: translate('ClientsContactsTable.actionsColumn'),
      align: 'left',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <UpdateContact updateContacts={record} isEdit />
          <Popconfirm
            title={translate('ClientsContactsTable.deletePopconfirm')}
            onConfirm={() => deleteContact(record.id, record.customer.id)}
          >
            <Tooltip title={translate('ClientsContactsTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record.id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isLoading,
        size: 'large',
      }}
      columns={columns}
      dataSource={listContacts}
      pagination={{
        total: clientContactTotal,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200'],
        defaultPageSize: 10,
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        onChange: (page, pageSize) => {
          setPageSize(pageSize!)
          setNumberPage(page)
        },
      }}
    />
  )
}

export default ClientsContactsTable
