/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PlusCircleOutlined, SaveOutlined } from '@ant-design/icons'
import {
  Button,
  Modal,
  Space,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Radio,
  Select,
} from 'antd'

import React, { useState } from 'react'

import { translate } from '~/utils/locale'

import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'
import { useDatabook } from '~/hooks/Maintenance/Databook/useDatabok'

const CreateDatabookModal: React.FC = () => {
  const { maintenance, isLoading } = useMaintenance()
  const { createDatabook } = useDatabook()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isExclusive, setIsExclusive] = useState(false)

  function onChangeRadio(value: any) {
    if (value.target.value === 'yes') {
      setIsExclusive(true)
    } else {
      setIsExclusive(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  function AddDocument(data: any) {
    const dataForm = {
      name: data.name,
      provided_responsible: data.provided_responsible,
      renovation_responsible: data.renovation_responsible,
      inclusion_date: data.inclusion_date,
      renovation_date: data.renovation_date,
      attchment: data.attchment,
      is_exclusive: isExclusive,
      plan_id: data.plan_id,
    }

    try {
      createDatabook(dataForm)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  return (
    <>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={showModal}
        style={{ width: '100%' }}
        type="primary"
      >
        {translate('MaintenanceDocumentsTab.AddDocuments')}
      </Button>

      <Modal
        title={translate('MaintenanceDocumentsTab.AddDocuments')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="700px"
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalClient.closeButton')}
            </Button>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              form="addDocumentMainForm"
              htmlType="submit"
            >
              {translate('MaintenanceDocumentsTab.Save')}
            </Button>
          </Space>,
        ]}
      >
        <Form layout="vertical" id="addDocumentMainForm" onFinish={AddDocument}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceDocumentsTab.DocumentName')}
                name="name"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceDocumentsTab.EnterDocumentName',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceDocumentsTab.DocumentLink')}
                name="attchment"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceDocumentsTab.EnterDocumentLink',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate(
                  'MaintenanceDocumentsTab.SupplyResponsibility',
                )}
                name="provided_responsible"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceDocumentsTab.EnterSupplyResponsibility',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate(
                  'MaintenanceDocumentsTab.RenewalResponsibility',
                )}
                name="renovation_responsible"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceDocumentsTab.EnterRenewalResponsibility',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceDocumentsTab.InclusionDate')}
                name="inclusion_date"
              >
                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceDocumentsTab.RenewalDate')}
                name="renovation_date"
              >
                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label="Documento exclusivo de algum plano de manutençao?"
                name="is_exclusive"
              >
                <Radio.Group onChange={onChangeRadio}>
                  <Radio value="yes">Sim</Radio>
                  <Radio value="no">Não</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {isExclusive ? (
              <Col span={12}>
                <Form.Item label="Escolha o plano" name="plan_id">
                  <Select
                    placeholder="Selecione o plano"
                    style={{ width: '100%' }}
                    loading={isLoading}
                  >
                    {maintenance?.map((m) => (
                      <Select.Option value={m._id} key={m._id}>
                        {m.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default CreateDatabookModal
