export default {
  pt: {
    SystemBoard: {
      cardTitle:
        'Planos de Manutenção e Conservação Patrimonial - Sistemas e Componentes',
      totalText: 'Total de',
      systemText: 'sistema',
      systemsText: 'sistemas',
      SearchComponent: 'Pesquisar componente',
    },
  },
  en: {
    SystemBoard: {
      cardTitle:
        'Property Maintenance and Conservation Plans - Systems e Components',
      totalText: 'Total of',
      systemText: 'system',
      systemsText: 'systems',
      SearchComponent: 'Search component',
    },
  },
  es: {
    SystemBoard: {
      cardTitle:
        'Planes de Mantenimiento y Conservación de Propiedades - Sistemas e Componentes',
      totalText: 'Total de',
      systemText: 'sistema',
      systemsText: 'sistemas',
      SearchComponent: 'Pesquisar componente',
    },
  },
}
