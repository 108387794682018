/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LoadingOutlined, ProfileOutlined } from '@ant-design/icons'
import { Space, Table, Button, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { format } from 'date-fns'

import React from 'react'
import { Link } from 'react-router-dom'

import { useListUsers } from '~/hooks/Users/useListUsers'
import { translate } from '~/utils/locale'

interface UsersProps {
  id: string
  active: boolean
  created_at: Date
  email: string
  updated_at: Date
  initial_time: string
  username: string
  contact: {
    name: string
  }
}

const UsersContactsTable: React.FC = () => {
  const {
    users,
    isLoading,
    setNumberPage,
    setPageSize,
    usersTotal,
    // deleteUsers,
  } = useListUsers()

  const usersFormat = users?.map((user: any) => {
    const dataFormat = {
      ...user,
      initial_time: format(
        new Date(user.created_at),
        `dd/MM/yyyy '${translate('UsersTable.dateString')}' HH:mm'h'`,
      ),
    }

    return dataFormat
  })

  const columns: ColumnsType<UsersProps> = [
    {
      title: translate('UsersTable.emailColumn'),
      align: 'left',
      key: 'email',
      dataIndex: 'email',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link to={`/usuarios/visualizar/${record.id}`}>{name}</Link>
      ),
    },
    {
      title: translate('UsersTable.contactColumn'),
      align: 'left',
      key: 'contact',
      dataIndex: ['contact', 'name'],
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('UsersTable.initialTimeColumn'),
      align: 'left',
      key: 'initial_time',
      dataIndex: 'initial_time',
    },
    {
      title: translate('UsersTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('UsersTable.viewTooltip')}>
            <Link to={`/usuarios/visualizar/${record.id}`}>
              <Button
                type="primary"
                ghost
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          {/* <Popconfirm
            title={translate('UsersTable.deletePopconfirm')}
            onConfirm={() => deleteUsers(record.id)}
          >
            <Tooltip title={translate('UsersTable.deleteTooltip')}>
              <Button
                ghost
                danger
                shape="circle"
                icon={<DeleteFilled />}
              />
            </Tooltip>
          </Popconfirm> */}
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record.id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isLoading,
        size: 'large',
      }}
      columns={columns}
      dataSource={usersFormat}
      pagination={{
        total: usersTotal,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200'],
        defaultPageSize: 10,
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        onChange: (page, pageSize) => {
          setPageSize(pageSize!)
          setNumberPage(page)
        },
      }}
    />
  )
}

export default UsersContactsTable
