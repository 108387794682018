/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'

import { translate } from '~/utils/locale'

import { useDatabook } from '~/hooks/Maintenance/Databook/useDatabok'
import CreateDatabookModal from './Create'
import DatabookTable from './Table'
import DocumentsFilterModal from '../Tabs/Documents/Filter'

const DatabookBoard: React.FC = () => {
  const { databookTotal, isLoading, isFetching } = useDatabook()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('SystemBoard.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {databookTotal !== undefined ? (
                <>
                  {databookTotal === 1
                    ? `${translate(
                        'SystemBoard.totalText',
                      )} ${databookTotal} ${translate(
                        'SystemBoard.systemText',
                      )}`
                    : `${translate(
                        'SystemBoard.totalText',
                      )} ${databookTotal} ${translate(
                        'SystemBoard.systemsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <DocumentsFilterModal />
          <Tooltip title="Novo Sistema">
            <CreateDatabookModal />
          </Tooltip>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <DatabookTable />
    </Card>
  )
}

export default DatabookBoard
