import React from 'react'
import MaintenanceBoard from '~/components/DataManipulation/Maintenance'
import { ListMaintenanceProvider } from '~/hooks/Maintenance/useMaintenance'
import { ContributorsTaskFilterProvider } from '~/hooks/Tasks/useListContributorsTask'

// import { Container } from './styles';

const Maintenance: React.FC = () => {
  return (
    <ListMaintenanceProvider>
      <ContributorsTaskFilterProvider>
        <MaintenanceBoard />
      </ContributorsTaskFilterProvider>
    </ListMaintenanceProvider>
  )
}

export default Maintenance
