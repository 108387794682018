/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, InputNumber, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'
import { translate } from '~/utils/locale'
import ClientModalMaintenance from '../../Create/Modal/Client'
import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'
import ContributorsMaintenanceTable from '../../Create/Modal/Contributor/Table'

// import { Container } from './styles';

interface UpdateMaintenanceProps {
  customer: string
  description: string
  name: string
  responsible_name: string
  phone: string
  future_days: number
}

const UpdateMaintenance: React.FC = () => {
  const { isLoading, maintenance, setIsEdit, updateMaintenance } =
    useShowMaintenance()

  const { clientData, contributorData } = useMaintenance()

  const [form] = Form.useForm()

  const [value, setValue] = useState<number | null>(null)

  const handleChange = (val: any) => {
    if (val !== null && val >= 0 && val <= 99) {
      setValue(val)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
    const isNumberKey = /^[0-9]$/.test(event.key)

    if (!isNumberKey && !allowedKeys.includes(event.key)) {
      event.preventDefault()
    }

    if (isNumberKey && value !== null && value.toString().length >= 2) {
      event.preventDefault()
    }
  }
  useEffect(() => {
    if (clientData?.id) {
      form.setFieldsValue({ customer: clientData?.name })
    }

    if (contributorData?.id) {
      form.setFieldsValue({
        responsible_name: contributorData?.name,
        phone: contributorData?.phone,
      })
    }
  }, [form, clientData, contributorData])

  if (isLoading || !maintenance) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const initialData = {
    customer: maintenance.customer?.name,
    name: maintenance.name,
    responsible_name: maintenance.responsible.name,
    phone: maintenance.responsible.phone,
    future_days: maintenance.future_days,
  }

  function UpdateMaintenance(data: UpdateMaintenanceProps) {
    const formatData = {
      name: data.name,
      customer: {
        name: data.customer,
      },
      responsible: {
        name: data.responsible_name,
        phone: data.phone,
      },
      description: data.description,
      future_days: data.future_days,
    }

    try {
      updateMaintenance(formatData)

      setIsEdit(false)
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <Card
      bordered={false}
      title={translate('MaintenanceDetailsTab.EditMaintenanceDetails')}
      extra={
        <Space>
          <Button
            htmlType="submit"
            form="updateMaintenance"
            type="primary"
            id="btn_update_maintenance"
          >
            {translate('MaintenanceDetailsTab.Save')}
          </Button>
          <Button icon={<CloseOutlined />} onClick={() => setIsEdit(false)}>
            {translate('MaintenanceDetailsTab.Cancel')}
          </Button>
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
    >
      <Form
        form={form}
        layout="vertical"
        id="updateMaintenance"
        initialValues={initialData}
        onFinish={UpdateMaintenance}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="Nome do Plano" name="name">
              <Input placeholder="Informe o nome do plano" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  {translate('MaintenanceDetailsTab.Client')}

                  <ClientModalMaintenance type="edit" />
                </Space>
              }
              name="customer"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>{translate('MaintenanceDetailsTab.Employee')}</p>
                  <ContributorsMaintenanceTable type="edit" />
                </Space>
              }
              name="responsible_name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('MaintenanceDetailsTab.ResponsiblePhone')}
              name="phone"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              label="Dias futuros para criar as atividades"
              name="future_days"
            >
              <InputNumber
                onKeyPress={handleKeyPress}
                style={{ width: '100%' }}
                placeholder="Informe os dias"
                value={value}
                onChange={handleChange}
                min={0}
                max={99}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label={translate('MaintenanceCreate.Description')}
              name="description"
            >
              <Input.TextArea
                placeholder={translate(
                  'MaintenanceCreate.DescriptionPlaceholder',
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default UpdateMaintenance
