/* eslint-disable no-underscore-dangle */
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Modal, Button, Tooltip, Form, Input, Row, Col, Space } from 'antd'

import React, { Fragment, useState } from 'react'
import { useSystem } from '~/hooks/Maintenance/System/useSystem'
import { translate } from '~/utils/locale'

interface SystemProps {
  _id: string
  name: string
  createdAt: string
  components: [{ name: string; _id: string }]
}
interface UpdateSystemData {
  data: SystemProps
}

const UpdateSystem: React.FC<UpdateSystemData> = ({ data }) => {
  const { updateSystem } = useSystem()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const initialData = {
    name: data.name,
    components: data?.components?.map((a) => {
      return { name: a.name }
    }),
  }

  function onUpdateSystem(valueForm: SystemProps) {
    try {
      const formatData = {
        name: valueForm.name,
        components: valueForm.components,
      }

      updateSystem(formatData, data._id)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Tooltip title={translate('UpdateSystem.EditSystem')}>
        <Button
          onClick={showModal}
          ghost
          shape="circle"
          type="primary"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('UpdateSystem.EditSystem')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="650px"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationPauseUpdate.closeButton')}
          </Button>,
          <Button
            type="primary"
            key="update"
            htmlType="submit"
            form="sytemUpdateForm"
          >
            {translate('TasksRegistrationPauseUpdate.saveButton')}
          </Button>,
        ]}
      >
        <Form
          onFinish={onUpdateSystem}
          initialValues={initialData}
          layout="vertical"
          id="sytemUpdateForm"
        >
          <Form.Item
            label={translate('UpdateSystem.SystemName')}
            name="name"
            rules={[
              {
                required: true,
                message: translate('SystemCreate.nameRule'),
              },
            ]}
          >
            <Input placeholder={translate('SystemCreate.namePlaceholder')} />
          </Form.Item>

          <Form.List name="components">
            {(fields, { add, remove }) => (
              <Row gutter={10}>
                {fields.map(({ key, name, ...restField }) => (
                  <Fragment key={key}>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        label={
                          <Space>
                            <Tooltip
                              title={translate('SystemCreate.DeleteComponent')}
                            >
                              <Button
                                ghost
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => remove(name)}
                                size="small"
                                danger
                              />
                            </Tooltip>
                            {translate('UpdateSystem.Component')}
                          </Space>
                        }
                        name={[name, 'name']}
                      >
                        <Input.TextArea
                          placeholder={translate(
                            'SystemCreate.componentPlaceholder',
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                ))}
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {translate('UpdateSystem.AddComponent')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  )
}

export default UpdateSystem
