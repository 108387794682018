export default {
  pt: {
    MaintenanceTable: {
      clientColumn: 'Cliente',
      periodicityColumn: 'Periodicidade',
      responsibleColumn: 'Colaborador Responsável pelo Plano',
      responsiblePhoneColumn: 'Celular do Colaborador',
      dataFontColumn: 'Fonte de Dados',
      systemColumn: 'Sistema',
      componentColumn: 'Componentes/Elementos',
      activityColumn: 'Atividades',
      deleteConfirmText: 'Deseja mesmo deletar o sistema?',
      activityText: 'atividade',
      activitiesText: 'atividades',
      actionsColumn: 'Ações',
      visualizationButon: 'Visualizar manutenções',
      deleteButton: 'Deletar',
      popConfirmText: 'Deseja mesmo deletar a manutenção?',
      periodicityText: 'A cada',
      yearsText: 'anos',
      yearText: 'ano',
      monthsText: 'meses',
      monthText: 'mes',
      weeksText: 'dias',
      weekText: 'dia',
      daysText: 'semanas',
      dayText: 'semana',

      filters: 'Filtros',
      filtersApply: 'Filtros Aplicados',
      maintenancePlanFilters: 'Filtros de Planos de Manutenção',
      client: 'Cliente',
      selectClient: 'Selecione o cliente',
      responsibles: 'Responsável(is)',
      selectResponsibles: 'Selecione o responsável',
      apply: 'Aplicar',
      clear: 'Limpar',
    },
  },
  en: {
    MaintenanceTable: {
      clientColumn: 'Customer',
      periodicityColumn: 'Periodicity',
      responsibleColumn: 'Collaborator',
      responsiblePhoneColumn: 'Collaborator Phone',
      dataFontColumn: 'Source Date',
      systemColumn: 'System',
      componentColumn: 'Components/Elements',
      activityColumn: 'Activities',
      deleteConfirmText: 'Do you really want to delete the system?',
      activityText: 'activity',
      activitiesText: 'activities',
      actionsColumn: 'Actions',
      visualizationButon: 'View maintenance',
      deleteButton: 'Delete',
      popConfirmText: 'Do you really want to delete maintenance?',
      periodicityText: 'Every',
      yearsText: 'years',
      yearText: 'year',
      monthsText: 'months',
      monthText: 'month',
      weeksText: 'days',
      weekText: 'day',
      daysText: 'weeks',
      dayText: 'week',

      filters: 'Filters',
      filtersApply: 'Applied Filters',
      maintenancePlanFilters: 'Maintenance Plan Filters',
      client: 'Client',
      selectClient: 'Select the client',
      responsibles: 'Responsible(s)',
      selectResponsibles: 'Select the responsible',
      apply: 'Apply',
      clear: 'Clear',
    },
  },
  es: {
    MaintenanceTable: {
      clientColumn: 'Cliente',
      periodicityColumn: 'Periodicidad',
      responsibleColumn: 'Colaborador',
      responsiblePhoneColumn: 'El celular del colaborador.',
      dataFontColumn: 'Fecha de origen',
      systemColumn: 'Sistema',
      componentColumn: 'Componente/Elementos',
      activityColumn: 'Actividades',
      deleteConfirmText: '¿Realmente quieres eliminar el sistema?',
      activityText: 'actividade',
      activitiesText: 'actividades',
      visualizationButon: 'Ver mantenimiento',
      actionsColumn: 'Aciones',
      deleteButton: 'Borrar',
      popConfirmText: '¿Realmente desea eliminar el mantenimiento?',
      periodicityText: 'Cada',
      yearsText: 'años',
      yearText: 'año',
      monthsText: 'meses',
      monthText: 'mes',
      weeksText: 'días',
      weekText: 'día',
      daysText: 'semanas',
      dayText: 'semana',

      filters: 'Filtros',
      filtersApply: 'Filtros Aplicados',
      maintenancePlanFilters: 'Filtros de Planes de Mantenimiento',
      client: 'Cliente',
      selectClient: 'Seleccione el cliente',
      responsibles: 'Responsable(s)',
      selectResponsibles: 'Seleccione el responsable',
      apply: 'Aplicar',
      clear: 'Limpiar',
    },
  },
}
