import React from 'react'
import CreateSystem from '~/components/DataManipulation/Maintenance/Systems/Create'
import { SystemProvider } from '~/hooks/Maintenance/System/useSystem'

// import { Container } from './styles';

const SystemCreate: React.FC = () => {
  return (
    <SystemProvider>
      <CreateSystem />
    </SystemProvider>
  )
}

export default SystemCreate
