import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, Space, Button, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import React from 'react'

import { useCategory } from '~/hooks/Equipments/Category/CategoryHooks'
import { translate } from '~/utils/locale'

import { Container } from './styles'
import UpdateCategory from './Update'

interface CategoryProps {
  title: string
  id: string
}

const EquipmentsCategory: React.FC = () => {
  const { category, deleteCategory, isLoading } = useCategory()

  const columns: ColumnsType<CategoryProps> = [
    {
      title: translate('EquipmentsCategory.categoryColumn'),
      align: 'left',
      key: 'title',
      dataIndex: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('EquipmentsCategory.actionsColumn'),
      align: 'left',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <UpdateCategory category={record} />
          <Popconfirm
            title={translate('EquipmentsCategory.deletePopconfirm')}
            onConfirm={() => deleteCategory(record.id)}
          >
            <Tooltip title={translate('EquipmentsCategory.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Container>
      <Table
        rowKey={(record) => record.id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        columns={columns}
        dataSource={category}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </Container>
  )
}

export default EquipmentsCategory
