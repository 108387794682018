/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import { Drawer, Form, Button, Col, Row, Input, Tooltip, Select } from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import { Container } from './styles'

import { translate } from '~/utils/locale'

import { useSystem } from '~/hooks/Maintenance/System/useSystem'

interface SystemProps {
  type: string
}

const SystemsFilterModal: React.FC<SystemProps> = ({ type }) => {
  const { handleUpdateFilters, handleResetFilters, filterOptions, system } =
    useSystem()

  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()

  const allComponents = system?.flatMap((sys) => sys.components)

  useEffect(() => {
    form.setFieldsValue({
      name: filterOptions?.name,
      components: filterOptions?.components,
    })
  }, [filterOptions, form])

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = (dataForm: any) => {
    const data = {
      name: dataForm.name,
      components:
        type === 'multiple' ? dataForm.components : [dataForm.components],
    }

    handleUpdateFilters(data)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        loading={!system}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('TaskTypesFilter.cleanFilterTooltip')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_sytems"
      >
        {isFilter
          ? translate('MaintenanceDocumentsTab.AppliedFilters')
          : translate('MaintenanceDocumentsTab.Filters')}
      </Button>
      <Drawer
        forceRender
        title="Filtro de Sistemas e Componentes"
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={translate('MaintenanceActivity.SelectComponents')}
                name="components"
              >
                {type === 'multiple' ? (
                  <Select
                    placeholder={translate(
                      'MaintenanceActivity.SelectComponentsElements',
                    )}
                    mode="multiple"
                  >
                    {allComponents?.map((c) => (
                      <Select.Option key={c?._id} value={c?._id}>
                        {c?.name}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Select
                    placeholder={translate(
                      'MaintenanceActivity.SelectComponentsElements',
                    )}
                  >
                    {allComponents?.map((c) => (
                      <Select.Option key={c?._id} value={c?._id}>
                        {c?.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={translate('MaintenanceDetailsTab.Systems')}
                name="name"
              >
                <Input
                  placeholder={translate('SystemCreate.namePlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('MaintenanceDocumentsTab.Apply')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('MaintenanceDocumentsTab.Clear')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default SystemsFilterModal
