/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusCircleOutlined } from '@ant-design/icons'
import {
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Card,
  Space,
  Button,
  Select,
} from 'antd'
import { PageHeader } from '@ant-design/pro-components'

import React, { useState } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import pt_BR from 'react-phone-number-input/locale/pt-BR.json'

import { Container } from './styles'
import { useClientsContacts } from '~/hooks/Clients/useContactClients'
import { useListClients } from '~/hooks/Clients/useListClients'
import { translate } from '~/utils/locale'

interface ContactPropsData {
  name: string
  email: string
  phone: string
  office: string
  is_responsible: boolean
  description: boolean
  customer_id: string
}

const CreateContactClients: React.FC = () => {
  const { createContacts } = useClientsContacts()
  const { clients } = useListClients()

  const [form] = Form.useForm()

  const [keepRegister, setKeepRegister] = useState(false)

  async function onCreateContactsClients(dataForm: ContactPropsData) {
    try {
      if (dataForm.phone) {
        dataForm.phone = dataForm.phone.replace(/[^0-9]/g, '')
      }

      const contactsClientsData = {
        name: dataForm.name,
        email: dataForm.email,
        phone: dataForm.phone,
        office: dataForm.office,
        is_responsible: dataForm.is_responsible,
        description: dataForm.description,
      }

      await createContacts(
        dataForm.customer_id,
        contactsClientsData,
        keepRegister,
      )
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <Container>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('ClientsContactsCreate.pageheaderTitle')}
      />
      <Card
        title={translate('ClientsContactsCreate.cardTitle')}
        extra={
          <Space size="small">
            <Checkbox
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('ClientsContactsCreate.keepRegisterText')}
            </Checkbox>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              form="newCreateContactsClients"
              htmlType="submit"
              id="btn_create_contacts"
            >
              {translate('ClientsContactsCreate.saveButtonText')}
            </Button>
          </Space>
        }
        bordered={false}
        style={{ width: '100%', padding: 30 }}
      >
        <Form
          id="newCreateContactsClients"
          layout="vertical"
          onFinish={onCreateContactsClients}
          form={form}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsCreate.customerLabel')}
                name="customer_id"
                rules={[
                  {
                    required: true,
                    message: translate('ClientsContactsCreate.customerRule'),
                  },
                ]}
              >
                <Select
                  placeholder={translate(
                    'ClientsContactsCreate.customerPlaceholder',
                  )}
                >
                  {clients?.map((client) => (
                    <Select.Option value={client.id} key={client.id}>
                      {client.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsCreate.nameLabel')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate('ClientsContactsCreate.nameRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'ClientsContactsCreate.namePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsCreate.emailLabel')}
                name="email"
                rules={[
                  {
                    required: true,
                    message: translate('ClientsContactsCreate.emailRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'ClientsContactsCreate.emailPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsCreate.phoneLabel')}
                name="phone"
                rules={[
                  () => ({
                    validator(rule, phone) {
                      if (phone === undefined || phone === null) {
                        return Promise.resolve()
                      }
                      if (phone && isValidPhoneNumber(phone)) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(translate('ClientsContactsCreate.phoneRule')),
                      )
                    },
                  }),
                ]}
              >
                <PhoneInput
                  placeholder={translate(
                    'ClientsContactsCreate.phonePlaceholder',
                  )}
                  labels={pt_BR}
                  defaultCountry="BR"
                  international
                  onChange={() => {
                    // Do nothing
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('ClientsContactsCreate.officeLabel')}
                name="office"
              >
                <Input
                  placeholder={translate(
                    'ClientsContactsCreate.officePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={16}>
            <Form.Item
              label={translate('ClientsContactsCreate.responsibleLabel')}
              name="is_responsible"
              valuePropName="checked"
            >
              <Checkbox>
                {translate('ClientsContactsCreate.responsibleCheckbox')}
              </Checkbox>
            </Form.Item>
          </Col>

          <Form.Item
            label={translate('ClientsContactsCreate.descriptionLabel')}
            name="description"
          >
            <Input.TextArea
              placeholder={translate(
                'ClientsContactsCreate.descriptionPlaceholder',
              )}
            />
          </Form.Item>
        </Form>
      </Card>
    </Container>
  )
}

export default CreateContactClients
