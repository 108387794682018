/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState, useCallback } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { useHistory } from 'react-router-dom'

interface MaintenanceProps {
  _id: string
  company_id: string
  customer?: string
  name?: string
  responsible: {
    name: string
    phone: string
  }
  activity: {
    _id: string
    title: string
  }
  documents: {
    name: string
  }
}

interface DataProps {
  id?: string
  name: string
  phone?: string
  components?: [{ name: string }]
  activities?: [{ activity: string }]
  type?: string
  interval?: number
  date?: Date
}

interface ContactProps {
  id?: string
  contact?: string
}

interface ClientProps {
  id?: string
  name?: string
  address?: {
    zip_code: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    state: string
    location: { x: number; y: number }
  }
}

interface FilterOptionsProps {
  name?: string
  customer?: string
  responsible?: string
}

interface MaintenanceContextData {
  maintenance?: MaintenanceProps[]
  createMaintenance(valueForm: object | undefined): void
  updateMaintenance(valueForm: object | undefined, maintenanceId: string): void
  deleteMaintenance(maintenanceId: string): void
  clientData?: ClientProps
  setClientData(value: ClientProps): void
  contactData?: ContactProps
  setContactData(value: ContactProps): void
  contributorData?: DataProps
  setContributorData(value: DataProps): void
  activityData?: DataProps
  setActivityData(value: DataProps): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  maintenanceTotal?: number
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
}

const MaintenanceContext = createContext<MaintenanceContextData>(
  {} as MaintenanceContextData,
)

export const ListMaintenanceProvider: React.FC = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:MainFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const history = useHistory()

  const [maintenanceTotal, setMaintenanceTotal] = useState<number>()

  const [clientData, setClientData] = useState<ClientProps>({} as ClientProps)
  const [contactData, setContactData] = useState<ContactProps>(
    {} as ContactProps,
  )
  const [contributorData, setContributorData] = useState<DataProps>(
    {} as DataProps,
  )

  const [activityData, setActivityData] = useState<DataProps>({} as DataProps)

  const {
    data: maintenance,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `gestao${selectedCompany}`,
      selectedCompany,
      maintenanceTotal,
      filterOptions,
    ],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/maintenances`,
          {
            params: {
              customer: filterOptions?.customer,
              name: filterOptions?.name,
              responsibleName: filterOptions?.responsible,
            },
          },
        )

        const { data } = response

        setMaintenanceTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(err.message, translate('useSystem.getErrorMessage'), locale)
      }
    },
  )

  async function createMaintenance(valueForm: object) {
    try {
      await api.post(`/company/${selectedCompany}/maintenance`, valueForm)

      refetch()
      message.success(translate('useMaintenance.MaintenanceCreatedSuccess'))

      history.push('/manutencao')
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.MaintenanceCreatedError'),
        locale,
      )
    }
  }

  async function updateMaintenance(valueForm: object, maintenanceId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/maintenance/${maintenanceId}`,
        valueForm,
      )

      message.success(translate('useMaintenance.updateSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteMaintenance(maintenanceId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/maintenance/${maintenanceId}`,
      )

      refetch()
      message.success(translate('useMaintenance.MaintenanceDeletedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.MaintenanceDeletedError'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      name: newFilterOptions?.name,
      customer: newFilterOptions?.customer,
      responsible: newFilterOptions?.responsible,
    }
    setFilterOptions(newFilters)
    localStorage.setItem('@Gstor:MainFilterOptions', JSON.stringify(newFilters))
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:MainFilterOptions')
    setFilterOptions({})
  }, [])

  return (
    <MaintenanceContext.Provider
      value={{
        maintenance,
        createMaintenance,
        updateMaintenance,
        deleteMaintenance,
        isLoading,
        refetch,
        isFetching,
        maintenanceTotal,
        clientData,
        contributorData,
        setClientData,
        contactData,
        setContactData,
        setContributorData,
        activityData,
        setActivityData,
        filterOptions,
        handleUpdateFilters,
        handleResetFilters,
      }}
    >
      {children}
    </MaintenanceContext.Provider>
  )
}

export function useMaintenance(): MaintenanceContextData {
  const context = useContext(MaintenanceContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
