import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import React from 'react'

import Text from 'antd/lib/typography/Text'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'
import { translate } from '~/utils/locale'
import { format } from 'date-fns'
import AddDocumentsModal from './Add'
import { ColumnsType } from 'antd/lib/table'
import DocumentsFilterModal from './Filter'

interface DocumentsProps {
  _id: string
  name: string
  provided_responsible: string
  renovation_responsible: string
  inclusion_date: Date
  renovation_date: Date
  attchment: string
}

const DocumentsTab: React.FC = () => {
  const { isLoading, maintenance, deleteDocument } = useShowMaintenance()

  if (isLoading || !maintenance) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }
  const columns: ColumnsType<DocumentsProps> = [
    {
      title: translate('MaintenanceDocumentsTab.DocumentName'),
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceDocumentsTab.SupplyResponsibility'),
      key: 'provided_responsible',
      dataIndex: 'provided_responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceDocumentsTab.RenewalResponsibility'),
      key: 'renovation_responsible',
      dataIndex: 'renovation_responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceDocumentsTab.InclusionDate'),
      key: 'inclusion_date',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Text>
          {record.renovation_date
            ? format(new Date(record?.inclusion_date), 'dd/MM/yyyy')
            : 'Nenhuma data informada'}
        </Text>
      ),
    },
    {
      title: translate('MaintenanceDocumentsTab.RenewalDate'),
      key: 'renovation_date',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Text>
          {record.renovation_date
            ? format(new Date(record?.renovation_date), 'dd/MM/yyyy')
            : 'Nenhuma data informada'}
        </Text>
      ),
    },
    {
      title: translate('MaintenanceDocumentsTab.DocumentLink'),
      key: 'attchment',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <>
          {record.attchment ? (
            <Typography.Link href={record.attchment} target="_blank">
              {record.attchment}
            </Typography.Link>
          ) : (
            'Nenhum link informado'
          )}
        </>
      ),
    },
    {
      title: 'Exclusivo de algum plano?',
      key: 'is_exclusive',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Tag color={record.is_exclusive ? 'geekblue' : 'red'}>
          {record.is_exclusive ? 'Sim' : 'Não'}
        </Tag>
      ),
    },
    {
      title: translate('TasksRegistrationPauseTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Popconfirm
            title={translate('MaintenanceDocumentsTab.ConfirmDeleteDocument')}
            onConfirm={() => deleteDocument(record._id)}
          >
            <Tooltip
              title={translate('TasksRegistrationPauseTable.deleteTooltip')}
            >
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Card
      bordered={false}
      title="Databook"
      extra={
        <Space>
          <AddDocumentsModal />
          <DocumentsFilterModal />
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
        marginTop: 15,
      }}
    >
      <Table
        rowKey={(record) => record?._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        size="small"
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={maintenance.documents ? maintenance.documents : []}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </Card>
  )
}

export default DocumentsTab
