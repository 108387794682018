import React from 'react'
import DatabookBoard from '~/components/DataManipulation/Maintenance/Databook'
import { DatabookProvider } from '~/hooks/Maintenance/Databook/useDatabok'
import { ListMaintenanceProvider } from '~/hooks/Maintenance/useMaintenance'

// import { Container } from './styles';

const Databook: React.FC = () => {
  return (
    <DatabookProvider>
      <ListMaintenanceProvider>
        <DatabookBoard />
      </ListMaintenanceProvider>
    </DatabookProvider>
  )
}

export default Databook
