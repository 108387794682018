export default {
  pt: {
    useSystem: {
      getErrorMessage: 'Erro ao visualizar os sistemas.',
      createSuccessMessage: 'Sistema criado com sucesso!',
      createErrorMessage: 'Erro ao criar um sistema.',
      updateSuccessMessage: 'Sistema atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar o sistema.',
      deleteSuccessMessage: 'Sistema deletado com sucesso!',
      deleteErrorMessage: 'Erro ao deletar o sistema.',
    },
  },
  en: {
    useSystem: {
      getErrorMessage: 'Error viewing systems.',
      createSuccessMessage: 'System created successfully!',
      createErrorMessage: 'Error creating the system.',
      updateSuccessMessage: 'System updated successfully!',
      updateErrorMessage: 'Error updating system.',
      deleteSuccessMessage: 'System deleted successfully!',
      deleteErrorMessage: 'Error deleting system.',
    },
  },
  es: {
    useSystem: {
      getErrorMessage: 'Error al ver los sistemas.',
      createSuccessMessage: '¡Sistema creado con éxito!',
      createErrorMessage: 'Error al crear sistema.',
      updateSuccessMessage: '¡Sistema actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar el sistema.',
      deleteSuccessMessage: '¡Sistema eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar el sistema.',
    },
  },
}
