/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import { Popconfirm, Space, Table, Tooltip, Button, Tag } from 'antd'
import {
  DeleteFilled,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

import { translate } from '~/utils/locale'
import { useListTickets } from '~/hooks/HelpDesk/useListTickets'

interface TicketsProps {
  _id: string
  title: string
  request_type_id: string
  customer: string
  customer_phone: string
  customer_email: string
  priority: string
  date_format: string
  description: string
  responsible: {
    id: string
    type: string
  }
  date: string
}

const HelpDeskTable: React.FC = () => {
  const { tickets, isLoading, deleteTicket } = useListTickets()

  const ticketsFormat = tickets?.map((ticket: any) => {
    const dataFormat = {
      ...ticket,
      date_format: format(
        new Date(ticket.date),
        `dd/MM/yyyy '${translate('UsersTable.dateString')}' HH:mm'h'`,
      ),
    }

    return dataFormat
  })

  const columns: ColumnsType<TicketsProps> = [
    {
      title: translate('HelpDeskTable.ticketColumn'),
      align: 'left',
      dataIndex: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('HelpDeskTable.clientColumn'),
      key: 'customer',
      dataIndex: 'customer',
    },
    {
      title: translate('HelpDeskTable.priorityColumn'),
      key: 'priority',
      render: (record) => (
        <>
          {record.priority === 'low' ? <Tag color="green">Baixa</Tag> : ''}
          {record.priority === 'medium' ? <Tag color="yellow">Média</Tag> : ''}
          {record.priority === 'high' ? <Tag color="red">Alta</Tag> : ''}
        </>
      ),
    },
    {
      title: translate('HelpDeskTable.dateColumn'),
      key: 'date',
      dataIndex: 'date_format',
    },
    {
      title: translate('HelpDeskTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('HelpDeskTable.viewTooltip')}>
            <Link to={`/ticket/${record._id}`}>
              <Button
                type="primary"
                ghost
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          <Popconfirm
            title={translate('HelpDeskTable.deletePopconfirm')}
            onConfirm={() => deleteTicket(record._id)}
          >
            <Tooltip title={translate('HelpDeskTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        dataSource={ticketsFormat}
        rowKey={(record) => record._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        scroll={{ x: 1000 }}
        columns={columns}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default HelpDeskTable
