export default {
  pt: {
    useDatabook: {
      getErrorMessage: 'Erro ao visualizar os databooks.',
      createSuccessMessage: 'Databook criado com sucesso!',
      createErrorMessage: 'Erro ao criar um databook.',
      updateSuccessMessage: 'Databook atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar o databook.',
      deleteSuccessMessage: 'Databook deletado com sucesso!',
      deleteErrorMessage: 'Erro ao deletar o databook.',
    },
  },
  en: {
    useDatabook: {
      getErrorMessage: 'Error viewing databooks.',
      createSuccessMessage: 'Databook created successfully!',
      createErrorMessage: 'Error creating the databook.',
      updateSuccessMessage: 'Databook updated successfully!',
      updateErrorMessage: 'Error updating databook.',
      deleteSuccessMessage: 'Databook deleted successfully!',
      deleteErrorMessage: 'Error deleting databook.',
    },
  },
  es: {
    useDatabook: {
      getErrorMessage: 'Error al ver los databooks.',
      createSuccessMessage: 'Databook creado con éxito!',
      createErrorMessage: 'Error al crear databook.',
      updateSuccessMessage: 'Databook actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar el databook.',
      deleteSuccessMessage: 'Databook eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar el databook.',
    },
  },
}
