/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd'

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'
import MoneyInput from '~/components/DataManipulation/Misc/MoneyInput'

import { useListParentClients } from '~/hooks/Clients/useListParentCustomer'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import { TaskFormsProvider } from '~/hooks/Tasks/Forms/useTaskForms'
import FormsCreateTask from '../Forms'

// import { Container } from './styles';

interface CreateFinancialTaskProps {
  form: any
}

const CreateFinancialTask: React.FC<CreateFinancialTaskProps> = ({ form }) => {
  const {
    clientData,
    teamData,
    amountHours,
    setAmountHours,
    setAmountHoursAdditional,
    numberKm,
    setNumberKm,
    setNumberKmAdditional,
  } = useTaskCreate()
  const { parentClients } = useListParentClients()

  const [value, setValue] = useState<number | undefined>(0)
  const [receive, setReceive] = useState<number | undefined>(0)

  const [amountHoursAddReceive, setAmountHoursAddReceive] = useState<
    number | undefined
  >(0)
  const [numberKmAddReceive, setNumberKmAddReceive] = useState<
    number | undefined
  >(0)

  const parentClientsId = clientData?.parent_cnpj

  const parentClientsData = parentClients?.filter(
    (p) => p._id === parentClientsId,
  )

  const handleTaskValue = (valueInCents: number | undefined) => {
    setValue(valueInCents)
  }

  const handleHourValue = (valueInCents: number | undefined) => {
    setAmountHoursAddReceive(valueInCents)
  }

  const handleKmValue = (valueInCents: number | undefined) => {
    setNumberKmAdditional(valueInCents)
  }

  const handleReceiveValue = (valueInCents: number | undefined) => {
    setReceive(valueInCents)
  }

  const handleHourValueReceive = (valueInCents: number | undefined) => {
    setAmountHoursAdditional(valueInCents)
  }

  const handleKmValueReceive = (valueInCents: number | undefined) => {
    setNumberKmAdditional(valueInCents)
  }

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        {parentClientsId ? (
          <Typography.Title level={5} style={{ margin: 0 }}>
            Valores acordados para receber do Cliente Principal &nbsp;
            <Tooltip title="Ver cliente">
              <Link
                to={`/clientePrincipal/visualizar/${parentClientsId}`}
                target="_blank"
              >
                {parentClientsData?.[0]?.companyName !== undefined ? (
                  <>{parentClientsData?.[0]?.companyName}</>
                ) : (
                  'Nenhum cliente principal encontrado.'
                )}
              </Link>
            </Tooltip>
          </Typography.Title>
        ) : (
          <Typography.Title level={5} style={{ margin: 0 }}>
            Valores acordados para receber do Cliente Principal
          </Typography.Title>
        )}

        {parentClientsId ? (
          <Typography.Text>
            Os valores pré-preenchidos foram extraídos do cadastro do Cliente
            Principal
          </Typography.Text>
        ) : (
          <Typography.Text>
            Os valores pré-preenchidos foram extraídos do cadastro do Tipo de
            Tarefa
          </Typography.Text>
        )}
      </div>

      <Row>
        <Col span={12}>
          <Form.Item label="Valor da Tarefa" name="value">
            <MoneyInput value={value} onChange={handleTaskValue} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <Form.Item label="Quantidade de horas" name="amount_hours">
            <Input
              placeholder="Horas"
              maxLength={2}
              value={amountHours}
              onChange={(e) => setAmountHours(parseInt(e.target.value))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <Space>
                Valor Hora Adicional
                <HelpTooltip title="Se excedido quantidade de horas máxima definida." />
              </Space>
            }
            name="amount_hours_additional"
          >
            <MoneyInput onChange={handleHourValue} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={12}>
          <Form.Item label="Quantidade de KM" name="number_km">
            <Input
              placeholder="Digite os km"
              value={numberKm}
              onChange={(e) => setNumberKm(parseInt(e.target.value))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <Space>
                Valor KM Adicional
                <HelpTooltip title="Se excedido quantidade de KM máximo definido." />
              </Space>
            }
            name="number_km_additional"
          >
            <MoneyInput onChange={handleKmValue} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <div>
        <div style={{ marginBottom: 10 }}>
          {teamData?.id ? (
            <>
              <Typography.Title level={5} style={{ margin: 0 }}>
                Valores acordados para pagar para Equipe &nbsp;
                <Tooltip title="Ver cliente">
                  <Link
                    to={`/equipe/visualizar/${teamData.id}`}
                    target="_blank"
                  >
                    {teamData?.name}
                  </Link>
                </Tooltip>
              </Typography.Title>

              <Typography.Text>
                Os valores pré-preenchidos foram extraídos do cadastro da
                Equipe.
              </Typography.Text>
            </>
          ) : (
            <Typography.Title level={5} style={{ margin: 0 }}>
              Valores acordados para pagar para o colaborador
            </Typography.Title>
          )}
        </div>

        <Row>
          <Col span={12}>
            <Form.Item
              label="Valor a pagar para equipe/colaborador"
              name="receive"
            >
              <MoneyInput value={receive} onChange={handleReceiveValue} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={14}>
          <Col span={12}>
            <Form.Item label="Quantidade de horas" name="amount_hours_receive">
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Horas"
                maxLength={2}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  Valor Hora Adicional
                  <HelpTooltip title="Se excedido quantidade de horas máxima definida." />
                </Space>
              }
              name="amount_hours_additional_receive"
            >
              <MoneyInput
                value={amountHoursAddReceive}
                onChange={handleHourValueReceive}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={14}>
          <Col span={12}>
            <Form.Item label="Quantidade de KM" name="number_km_receive">
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Digite os km"
                value={numberKm}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  Valor KM Adicional
                  <HelpTooltip title="Se excedido quantidade de KM máximo definido." />
                </Space>
              }
              name="number_km_additional_receive"
            >
              <MoneyInput
                value={numberKmAddReceive}
                onChange={handleKmValueReceive}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <Divider>Formulaŕios</Divider>
      <TaskFormsProvider>
        <FormsCreateTask form={form} />
      </TaskFormsProvider>
    </>
  )
}

export default CreateFinancialTask
