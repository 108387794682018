/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { Card, Input, Space, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { useMemo, useState } from 'react'
import { useSystem } from '~/hooks/Maintenance/System/useSystem'
import { translate } from '~/utils/locale'

import SystemTable from './Table'
import CreateSystem from './Create'
import SystemsFilterModal from './Filter'

const SystemBoard: React.FC = () => {
  const { system, systemTotal, isLoading, isFetching } = useSystem()

  const [searchElement, setSearchElement] = useState<string>('')

  const lowerBusca = searchElement.toString().toLowerCase()

  const elementData = useMemo(() => {
    return system?.filter(
      (datas) =>
        datas.components?.some((component: any) =>
          component.name.toLowerCase().includes(lowerBusca),
        ),
    )
  }, [searchElement, system])

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('SystemBoard.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {systemTotal !== undefined ? (
                <>
                  {systemTotal === 1
                    ? `${translate(
                        'SystemBoard.totalText',
                      )} ${systemTotal} ${translate('SystemBoard.systemText')}`
                    : `${translate(
                        'SystemBoard.totalText',
                      )} ${systemTotal} ${translate(
                        'SystemBoard.systemsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <Input
            placeholder={translate('SystemBoard.SearchComponent')}
            style={{ width: '100%' }}
            value={searchElement}
            size="large"
            onChange={(e) => setSearchElement(e.target.value)}
            prefix={<SearchOutlined />}
          />
          <SystemsFilterModal type="multiple" />
          <Tooltip title="Novo Sistema">
            <CreateSystem />
          </Tooltip>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <SystemTable data={searchElement ? elementData : system} />
    </Card>
  )
}

export default SystemBoard
